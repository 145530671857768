  <footer class="page-footer">
    <div class="footer-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6">
            <p class="mb-0 text-muted">{{ 'COMMON.all_rights_reserved' | translate }}</p>
          </div>
          <div class="col-12 col-sm-6 d-flex justify-content-end">
            <p class="mb-0 text-muted">{{ 'COMMON.powered_by' | translate }}{{' '}} <b>
              <a href="https://elegantco.com.sa//" target="_blank" >
                Cherry rent a car - IT Department 
              <!-- <img  class="bridges-logo-img" src="../../../../../assets/img/bridges-logo.png" /> -->
            </a>
          </b></p>
          </div>

        </div>
      </div>
    </div>
  </footer>
