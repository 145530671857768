import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { SessionDataService } from "../services/session-data.service";
import { catchError, finalize } from "rxjs/operators";
import { AuthGuard } from "../shared/auth.service";
import { LoaderService } from "../loader/loader.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private sessionDataService: SessionDataService,
    private authenticationService: AuthGuard,
    public loaderService: LoaderService , 
    private router:Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.sessionDataService.getAccessToken();
    if(req.url.indexOf("/tracking") == -1){
      this.loaderService.isLoading.next(true);
    }
    if (token) {
      if(!(req.url.includes('/user/') && req.url.includes('/otp/')) ){
      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + token),
      });
      }
    }
    req = req.clone({
      headers: req.headers.set('sourceChannel', 'ADMINISTRATION_PORTAL'),
    });
    

    return next.handle(req).pipe(
      finalize(() => {
        this.loaderService.isLoading.next(false);
      }),
      catchError((err) => {
        if ([401, 403].includes(err.status)) {
          // auto logout if 401 or 403 response returned from api
          this.authenticationService.logout();
          
          // Reload the app
          location.reload();
        }
        console.log(err);
        return throwError(err);
      })
    );
  }


  
}
