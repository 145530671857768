import { Directive, OnInit, Input } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from '../models/user.model';
import { SessionDataService } from '../services/session-data.service';


@Directive({ selector: '[appHasPrivilage]'})
export class HasPrviageDirective implements OnInit {
    privilages: string[] =[];
    currentUser!: User;
    
    @Input() 
    set appHasPrivilage(privilages: string[]) {
        if (!privilages || !privilages.length) {
            throw new Error('privilages value is empty or missed');
        }
        this.privilages = privilages;
    }
    
    constructor(
        private templateRef: TemplateRef<any>,
        private sessionDataService: SessionDataService,
        private viewContainer: ViewContainerRef
    ) { 
        this.sessionDataService.currentUser.subscribe(
            (x) => (this.currentUser = x)
          );
    }

    ngOnInit() {
        let hasAccess = false;
        if((this.privilages.length ==1 && this.privilages[0] === 'ALL') ||
        (this.privilages.indexOf(this.currentUser.authoritiesSet[0]) != -1)){
            hasAccess = true;
        }
        if (hasAccess) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}