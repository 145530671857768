import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { constants } from "../constants/constants";
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FleetService {

  constructor(private http: HttpClient) { }

  async getVehicleDetailsByID(vehicleId: string) {
    const url = environment.baseURL + constants.API.GET_VEHICLE_DETAILS;
    let res: any = await this.http.get(url.replace("{serviceVehicleId}", vehicleId)).toPromise();
    return res;
  }

  async getVehicleTrackingDetailsByID(vehicleId: string) {
    const url = environment.baseURL + constants.API.GET_TRACKING_VEHICLE_DETAILS;
    let res: any = await this.http.get(url.replace("{serviceVehicleId}", vehicleId)).toPromise();
    return res;
  }

  async executeVehicleCommand(vehicleId: any, command: any): Promise<any> {
    const url = environment.baseURL + constants.API.EXECUTE_VEHICLE_COMMAND;
    let res: any = await this.http.post(url
      .replace("{serviceVehicleId}", vehicleId)
      .replace("{command}", command), {}).toPromise();
    return res;
  }

  async changeVehicleStatus(vehicleId: any, status: any): Promise<any> {
    const url =
      environment.baseURL +
      constants.API.CHANGE_VEHICLE_STATUS_API.replace("{serviceVehicleId}", vehicleId).replace("{serviceVehicleStatus}", status);
    await this.http.patch(url, {}).toPromise();
  }

  async addVehicle(vehicle: any): Promise<any> {
    const url = environment.baseURL + constants.API.ADD_VEHICLE;
    let res: any = await this.http.post(url, vehicle).toPromise();
    return res;
  }

  async editVehicle(vehicle: any): Promise<any> {
    const url = environment.baseURL + constants.API.EDIT_VEHICLE.replace("{serviceVehicleId}", vehicle.id);
    let res: any = await this.http.patch(url, vehicle).toPromise();
    return res;
  }

  getPossibleServiceProviders(vehicleId: string) {
    const url = environment.baseURL + constants.API.GET_POSSIBLE_VEHICLE_SERVICE_PROVIDER.replace("{serviceVehicleId}", vehicleId);
    return this.http.get(url)
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  async addServiceProvider(vehicleId: string,serviceProviderId:string): Promise<any> {
    const url = environment.baseURL + constants.API.ADD_VEHICLE_SERVICE_PROVIDER
    .replace("{serviceVehicleId}", vehicleId)
    .replace("{serviceproviderId}", serviceProviderId);
    let res: any = await this.http.post(url, {}).toPromise();
    return res;
  }

  async deleteServiceProvider(vehicleId: string,serviceProviderId:string): Promise<any> {
    const url = environment.baseURL + constants.API.DELETE_VEHICLE_SERVICE_PROVIDER
    .replace("{serviceVehicleId}", vehicleId)
    .replace("{serviceproviderId}", serviceProviderId);
    let res: any = await this.http.delete(url, {}).toPromise();
    return res;
  }


  getPossibleAssistantServiceProviders(vehicleId: string) {
    const url = environment.baseURL + constants.API.GET_POSSIBLE_VEHICLE_ASSISTANT_SERVICE_PROVIDER.replace("{ServiceVehicleId}", vehicleId);
    return this.http.get(url)
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }

  async addAssistantServiceProvider(vehicleId: string,assistantId:string): Promise<any> {
    const url = environment.baseURL + constants.API.ADD_VEHICLE_ASSISTANT_SERVICE_PROVIDER
    .replace("{serviceVehicleId}", vehicleId)
    .replace("{assistantId}", assistantId);
    let res: any = await this.http.post(url, {}).toPromise();
    return res;
  }

  async deleteAssistantServiceProvider(vehicleId: string,assistantId:string): Promise<any> {
    const url = environment.baseURL + constants.API.DELETE_VEHICLE_ASSISTANT_SERVICE_PROVIDER
    .replace("{serviceVehicleId}", vehicleId)
    .replace("{assistantId}", assistantId);
    let res: any = await this.http.delete(url, {}).toPromise();
    return res;
  }

	generateLocationURL(serviceVehicleId: any): Observable<any> {
		const endpoint = environment.baseURL.concat(constants.API.GENERATE_LOCATION_URL)
    .replace("{serviceVehicleId}", serviceVehicleId);
		return this.http.post(endpoint,{});
	}

  loadLiveTrackingInfo(token: string): Observable<any> {
		const endpoint = environment.baseURL.concat(constants.API.LOAD_LIVE_TRACKING_INFO)
    .replace("{token}", token);
		return this.http.get(endpoint);
	}

}
