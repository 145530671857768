import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Client, Message, over, StompHeaders, StompSubscription, } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../environments/environment';
import { filter, first, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { constants, SocketClientState } from '../../app/constants/constants';
import { SessionDataService } from './session-data.service';
import { HttpClient } from '@angular/common/http';

//https://dimitr.im/websockets-angular
@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private client: Client;
  private state: BehaviorSubject<SocketClientState>;

  constructor(private sessionDataService:SessionDataService,private http: HttpClient) {
    this.client = over(new SockJS(environment.notificationsWebSocketApi));
    this.state = new BehaviorSubject<SocketClientState>(SocketClientState.ATTEMPTING);
    let token = this.sessionDataService.getAccessToken();
    let headers:StompHeaders = {
      "X-Authorization": 'Bearer ' + token
    }
    this.client.connect(headers, () => {
      this.state.next(SocketClientState.CONNECTED);
    });
  }

  connect(): Observable<Client> {
    return new Observable<Client>(observer => {
      this.state.pipe(filter(state => state === SocketClientState.CONNECTED)).subscribe(() => {
        observer.next(this.client);
      });
    });
  }

  ngOnDestroy() {
    this.connect().pipe(first()).subscribe(inst => inst.disconnect(null));
  }

  onMessage(topic: string, handler = NotificationService.jsonHandler): Observable<any> {

    return this.connect().pipe(first(), switchMap(inst => {
      return new Observable<any>(observer => {
        const subscription: StompSubscription = inst.subscribe(topic, message => {
          observer.next(handler(message));
        });
        return () => inst.unsubscribe(subscription.id);
      });
    }));
  }

  onPlainMessage(topic: string): Observable<string> {
    return this.onMessage(topic, NotificationService.textHandler);
  }

  static jsonHandler(message: Message): any {
    return JSON.parse(message.body);
  }

  static textHandler(message: Message): string {
    return message.body;
  }

  async getAllNotifications(page:any,size:any) {
    const url = environment.baseURL + constants.API.GET_ALL_NOTIFICATIONS+"?unread=true&page="+page+"&size="+size;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getNotificationById(notificationId:any) {
    const url = environment.baseURL + constants.API.GET_NOTIFICATION_BY_ID;
    let res: any = await this.http.get(url.replace("{id}", notificationId)).toPromise();
    return res;
  }
}