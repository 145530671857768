 import { formatDate } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { DatePipe } from '@angular/common'
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { error } from "@angular/compiler/src/util";


@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss']
})
export class ListFilterComponent implements OnInit {

  @Input() filterFields: any = [];
  @Input() displayedColumns: any = null;
 
  @Input() filterCollapseView: boolean = false;
  @Input() isFilterable: boolean = false;

  @Input() isHorizontalFilter: boolean = false;
  @Input() isVerticalFilter: boolean = false;
  @Input() loadingIndicator: boolean = false;
  @Input() updatedSearchTerm: any = "";
  @Input() expandableActions: any = [];
  @Output() editItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchTerm: EventEmitter<string> = new EventEmitter<string>();
  @Output() collapsed: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchTextChanged = new Subject<string>();
  changeFilters: FormGroup;
  @Input() hasAction: boolean = false;
  @Input() action: any;
  @Output() actionOutput: EventEmitter<any> = new EventEmitter<any>();

  tableData: any = { heading: [], data: [] };
  size = 10;
  selected = [];

  SelectionType = SelectionType;
  basicMessages = {
    emptyMessage: "",
  };

  subscription: any;
  searchPlaceholder: string;

  constructor(private formBuilder: FormBuilder,public datePipe:DatePipe , 
    private translate: TranslateService) {
      this.changeFilters = this.formBuilder.group({});
      this.translate.get("COMMON.NO_DATA_TO_DISPLAY").subscribe((res) => {
        this.basicMessages.emptyMessage = res;
      });
     }
  public isCollapsed = true;
  ngOnInit(): void {
    if(window.innerWidth >= 992){
      this.isCollapsed = false
    }
    this.filterFields = [];

  }


  
  ngOnChanges() {
      // build filterFields
    if (this.filterFields) {
      let form = {};
      this.filterFields.forEach((element) => { 

        if (((element.dataType === "SELECT" && !element.enumValues )|| element.dataType === "MULTI_SELECT") ) {
          element.selectValues = element.selectValues?.map((item: any) => {
            return {
              ...item,
              name:
                this.translate.currentLang === "en" && item.nameEn
                  ? item.nameEn
                  : this.translate.currentLang === "ar" && item.nameAr
                  ? item.nameAr
                  : this.translate.currentLang === "en" && item.valueEn
                  ? item.valueEn
                  : this.translate.currentLang === "ar" && item.valueAr
                  ? item.valueAr:
                  this.translate.currentLang === "ar" && item.placeHolderAr
                  ? item.placeHolderEn:
                   "",
                   optionValue :  item.value ? item.value : item.id
            };
          });
        }

        
         if(element?.dataType === 'MULTI_SELECT'  ){
            element.selectValues = element.selectValues ? element.selectValues.map((item: any) => {
                if(!element.filteredValues){
                return {
                  ...item,
                  checked: false
                    
                };
              }else{
                for(let choice of element.filteredValues){
                  if(this.checkMultiSelectEquality(choice , item)){
                    return {
                    ...item,
                    checked:   true 
                      
                  };
                } 
              } 
              return {
                ...item,
                checked:   false 
                  
              };
            }
          }):undefined;
         }
        else{
          Object.assign(form, {
            [element.fieldName]: [
              element?.filteredValues
                ? element?.filteredValues[0].value ? element?.filteredValues[0].value
                 : element?.filteredValues[0].id ? 
                 element?.filteredValues[0].id :
                 element?.filteredValues[0]
                : element?.initialValue
                ? element?.initialValue
                : element.dataType === "SELECT"
                ? null
                : "",
            ],
          });
        }
        
      });
      form = { ...form };
      this.changeFilters = this.formBuilder.group({ ...form });
      
      this.filterFields.forEach((element) => { 
        
      });
      // this.changeFilters
      // .get("searchTerm")
      // .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      // .subscribe((term) => {
      //   this.updateSearch(term);
      // });

      // subscribe to change
      this.filterFields.forEach((element) => { 
        if (element.dataType === "BOOLEAN") {
          const booleanValue = element?.filteredValues
            ? element?.filteredValues[0] === "true"
            : false;
          this.changeFilters.get(element.fieldName).setValue(booleanValue);
        }
        if (element.dataType === "DATE" && element?.filteredValues?.length>0) {
          this.changeFilters.get(element.fieldName).setValue(new Date(element?.filteredValues[0]));
        }

        if (
          element.dataType === "STRING" ||
          element.dataType === "DOUBLE" ||
          element.dataType === "LONG"
        ) {
          this.changeFilters
            .get(element.fieldName)
            .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((x) => {
              this.updateFilters(element.fieldName, x);
            });
        } else if ( !(element.dataType === "SELECT")&&
                    !(element.dataType === "MULTI_SELECT") ){
          
          this.changeFilters
            .get(element.fieldName)
            .valueChanges.subscribe((x) => {
              //temp solution for date filter infinite loop
              if(element.dataType === "DATE"){
               
                let dateFilter = this.filterFields.find((filterField) =>{return element.fieldName == filterField.fieldName})
                if(dateFilter.filteredValues){
                  if(x != dateFilter.filteredValues){
                    this.updateFilters(element.fieldName, x);
                  }
                }else{
                  this.updateFilters(element.fieldName, x);
                }
              }else if (!(element.dataType === "SELECT" || element.dataType === "MULTI_SELECT")){
                 this.updateFilters(element.fieldName, x);
              }
            });
            if (
              (element.dataType === "SELECT" || element.dataType === "MULTI_SELECT")&&
              !element.enumValues &&
              element.selectValues.length > 0
            ) {
              element.selectValues = element.selectValues.map((item: any) => {
                return {
                  ...item,
                  name:
                    this.translate.currentLang === "en" && item.nameEn
                      ? item.nameEn
                      : this.translate.currentLang === "ar" && item.nameAr
                      ? item.nameAr
                      : this.translate.currentLang === "en" && item.valueEn
                      ? item.valueEn
                      : this.translate.currentLang === "ar" && item.valueAr
                      ? item.valueAr :
                       
                  this.translate.currentLang === "ar" && item.placeHolderAr
                  ? item.placeHolderEn:
                   "",
                      
                      optionValue :  item.value ? item.value : item.id
                };
              });
            }
            if(element.dataType === 'MULTI_SELECT'  ){
              element.selectValues = element.selectValues.map((item: any) => {
                if(!element.filteredValues){
                return {
                  ...item,
                  checked: false
                    
                };
               }else{
                  for(let choice of element.filteredValues){
                   if(this.checkMultiSelectEquality(choice , item)){
                    return {
                      ...item,
                      checked:   true 
                        
                    };
                  } 
                 } 
                return {
                  ...item,
                  checked:   false 
                    
                };
               }
              });
            
             }
            
    
        }
      });
    }
  }

  updateSearch(searchTerm) {
    this.searchTerm.emit(searchTerm);
    // Whenever the filter changes, always go back to the first page
  }

  updateFilters(name, value) {
    
    console.log(name, "value changed");
    console.log(this.filterFields);
    const filters = this.filterFields.map((element: any) => {
      let tempVal = {...value} ; 
      if (element.fieldName === name) {
        if (
          (element.dataType === "SELECT" || element.dataType === "MULTI_SELECT") &&
          
          value !== null
        ) {
          delete tempVal.name;
          delete tempVal.optionValue;
        }
        if (
          (element.dataType === "SELECT" && value === null) ||
          (element.dataType === "BOOLEAN" && !value)
          ||(element.dataType === "MULTI_SELECT" && value === null)
        ) {
          delete element?.filteredValues;
          return element;
        } else if (element.dataType === "BOOLEAN" && value)
          return { ...element, filteredValues: [`${value}`] };
        else if (element.dataType === "DATE_TIME") {
          console.log(value) ; 
          //var dateString = value.replace(/-/g, '/') ;
         console.log(new Date(value).toISOString()) ;
          return {
            ...element,
            filteredValues: [new Date(value).toISOString()],
          };
        }else if (element.dataType === "DATE") {
          console.log(value) ; 
         console.log(new Date(value).toISOString()) ;
          return {
            ...element,
            filteredValues: [new Date(value).toISOString()],
          };
        }
        else if (element.dataType === "MULTI_SELECT") {

          if(value.checked){
            delete tempVal.checked; 
            delete tempVal?.name;
            delete tempVal?.optionValue;
            return { ...element, filteredValues: element?.filteredValues  ? [tempVal].concat(element?.filteredValues) :  [tempVal] };
          }
          else{
            delete tempVal.checked; 
            delete tempVal?.name;
            delete tempVal?.optionValue
             element?.filteredValues.forEach((choice) =>{
              if(this.checkMultiSelectEquality(choice , tempVal)){
                element.filteredValues = this.arrayRemove(element.filteredValues , tempVal) ;
               }
             });
             return { ...element, filteredValues: element?.filteredValues };
          }
        }else if(element.dataType === "SELECT" && !element.enumValues){
            return { ...element, filteredValues: [tempVal]};
        }
        else return { ...element, filteredValues: [value]};
      } else return element;
    });
    console.log(value);
    this.updatedFilters.emit(filters);
    
  }


  onCheckboxChange(e , fieldName) {
    //this.updateFilters(element.fieldName, x);
    this.updateFilters(fieldName, e );
  }

  onCheckboxChanged(item , fieldName , $event) {
     
      item.checked = $event.target.checked ; 
     
   
    this.updateFilters(fieldName, item );
  }

  
    arrayRemove(arr, value) { 
    
    return arr.filter(function(ele){ 
      return ele.id? ele.id != value.id: ele.value != value.value ; 
         
    });
}

checkMultiSelectEquality(item1 , item2){
    return item1.id?item1.id===item2.id:item2.value === item1.value ;
}

clearFilters(){
  //this.changeFilters.reset() ; 
  const filters = this.filterFields.map((element: any) => {
    delete element?.filteredValues;
          return element;
  });
  this.updatedFilters.emit(filters);
}

changeCollapsed(){
  this.collapsed.emit(false) ; 
}

changeMaxViewedElements(item){
   item.maxViewedOptionsCount += (item.selectValues.length - item.maxViewedOptionsCount) > 5 ? 5 : (item.selectValues.length - item.maxViewedOptionsCount) ;
}

showAllOptions(item){
  item.maxViewedOptionsCount = (item.selectValues.length)  ;
 
}

viewSeeMore(item){
   return (item.selectValues.length - item.maxViewedOptionsCount) <= 0 ? false : true ;
}
}
