export const constants = {
    USER_ROLES: {
      admin: "ROLE_ADMIN",
      operationManager: "ROLE_OPS_MANAGER",
      serviceProvider: "ROLE_SERVICE_PROVIDER",
      stationSupervisor:"ROLE_STATION_SUPERVISOR",
      stationAttendant:"ROLE_STATION_ATTENDANT",
      supervisor: "ROLE_SUPERVISOR",
      accountant:"ROLE_ACCOUNTANT"
    },
    PRISISTED_KEYS: {
      ACCESS_TOKEN: "ACCESS_TOKEN",
      REMEMBER_ME: "REMEMBER_ME",
      CURRENT_USER: "CURRENT_USER",
    },
    API: {
      LOGIN_API: "/user/auth",
      VERIFY_OTP:"/user/profile/verify/{regReqId}/{otpCode}",
      GET_PROFILE_API: "/user/profile",
      UPDATE_PROFILE_API: "/user/profile",
      GET_USER_PHOTO_API: "/user/profile/photo",
      UPLOAD_USER_PHOTO_API: "/user/profile/photo",
      FORGET_PASSWORD_API: "/user/forgotPassword",
      RESET_PASSWORD_API: "/user/restPassword",
      CHANGE_PASSWORD_API: "/user/profile/password",
      CLAIM_TOKEN_API: "/frontend/aid/claimToken",
      REQUEST_ADDITTIONAL_ACCESS_API: "/restapi/user/roles/{roleName}" ,
      SUBMIT_BUSINESS_USER_REGISTRATION_API: "/account/user/registration",
      VERIFY_REGISTRATION_OTP_API: "/user/",
      LOOKUPS_CITY: "/lookups/city",
      LOOKUPS_COUNTRY: "/lookups/country",
      LOOKUPS_NATIONALITY: "/lookups/nationality",
      LOOKUPS_COLOR: "/lookups/color",
      SEARCH_USERS_API: "/search/user",
      INVITE_USER_API: "/user/invitation",
      UPDATE_USER_ACCESS_RIGHTS_API: "/user/{userId}/accessRights",
      REGISTER_USER_API: "/user/profile",
      REGISTER_USER_API_FROM_INVITATION: "/user/registration",
      CHANGE_USER_ACCESS_RIGHTS_API: "/user/{userId}/accessRights",
      REQUEST_ADDITIONAL_ACCESS: "/user/roles/{roleName}",
      MAIL_USER_ACTIVATION_API: "/user/email/verification",
      DELETE_USERS_API: "/user/{userId}",
      GET_USER_PROFILE_API: "/user/{userId}/profile",
      GET_USER_PIC_API: "/user/{userId}/profile/photo",
      GET_USER_DOCS_API: "/customer/{userId}/document",
      UPLOAD_USER_DOC_API: "/customer/{userId}/document/{fileType}",
      GET_LOOKUP_API: "/lookups/enums/{lookupName}",
      GET_ADDRESS_API: "/account/address?customerId={userId}",
      ACTIVATE_USER_API: "/user/{userid}/activate",
      DEACTIVATE_USER_API: "/user/{userid}/deactivate",
      LOOKUP_ENUM_API: "/lookups/enums/{enumName}",
      LOOKUP_ENUM_LOCALIZED_API: "/lookups/enums/i18n/{enumName}",
      EXPORT_FILTER: "/search/export",
      SEARCH_API: "/search",
      GET_ALL_NOTIFICATIONS: "/notification",
      GET_NOTIFICATION_BY_ID: "/notification/{id}",
      DOWNLOAD_DOCUMENT_BY_ID_API:"/docs/{documentID}",
      GET__CURRENT_USER_ACTIVITY:"/user/activitieslog" ,
      GET_USER_ACTIVITY:"/user/{userId}/activitieslog" ,

      DEACTIVATE_USER : "/user/{userId}/status/INACTIVE",
      ACTIVATE_USER : "/user/{userId}/status/ACTIVE" ,

      VERIFY_MAIL_OR_MOBILE_OTP : "/user/{userId}/otp/{otpcode}",
      USER_RESEND_OTP  : "/user/{userId}/otp/resend",

      GET_DASHBOARD_TOP_TEN:"/dashboard/infoCards/top10/{topTenType}",
      GET_DASHBOARD_ACCUMULATED_INFO_CARDS:"/dashboard/accumulatedInfoCards",
      GET_DASHBOARD_REVENUE_HISTORY:"/dashboard/revenue/LAST_YEAR",
      GET_RECENT_SERVICE_ORDERS:"/dashboard/recentServiceOrder",
      GET_DASHBOARD_TIME_FRAME_INFO_ORDERS:"/dashboard/statisticsCards/{timeFrame}",
      GET_DASHBOARD_TIME_FRAME_STATIONS_REVENUE:"/dashboard/revenue/stations/{timeFrameOption}",

      APPROVE_CUSTOMER_FEEDBACK:"/customer/feedback/{customerFeedbackId}/status/APPROVED",
      REJECT_CUSTOMER_FEEDBACK:"/customer/feedback/{customerFeedbackId}/status/REJECTED",
      RESOLVE_SUPPORT_TICKET:"/supportTicket/{ticketId}/status/RESOLVED",
      GET_SUPPORT_TICKET_DETAILS:"/supportTicket/{ticketId}",
      ADD_SUPPORT_TICKET_COMMENT:"/supportTicket/{ticketId}/comments",
      GET_POSSIBLE_TICKET_ASSIGNEES:"/supportTicket/{ticketId}/assignee",
      CHANGE_TICKET_ASSIGNEE:"/supportTicket/{ticketId}/currentAssignee/{assigneeId} ",
      ADD_SUPPORT_TICKET:"/supportTicket",


      GET_CUSTOMER_USER_ADDRESS_API: "/customer/{userId}/address",
      GET_CUSTOMER_USER_PAYMENTS_API: "/customer/{userId}/payment",
      GET_CUSTOMER_USER_WALLET_API: "/customer/{userId}/wallet",
      CHANGE_CUSTOMER_SEGMENT_API: "/customer/{userId}/customerSegment/{segment}",

      SET_USER_UNAVAILABLE_API: "/user/{userId}/serviceProvider/availabilityStatus/UNAVAILABLE",
      SET_USER_AVAILABLE_API: "/user/{userId}/serviceProvider/availabilityStatus/AVAILABLE",
      CHANGE_EMPLOYEE_CODE_API: "/user/{userId}/employeCode/{employeeCode}",
      CHANGE_SERVICE_AREA_API: "/user/{userId}/serviceProvider/serviceArea/{serviceAreaId}",

      ADD_CUSTOMER:"/customer/walkinRegistration",
      GET_STATIONS_API: "/stations",
      GET_SERVICE_AREAS_API: "/serviceArea/lookup?status=IN_COVERAGE",
      GET_USER_STATISTICS_API: "/user/{userId}/statisticsCard",
      GET_USER_STATIONS_API: "/user/{userId}/station",
      GET_USER_SERVICE_ORDERS_API: "/user/{userId}/serviceOrder",
      GET_CUSTOMER_SERVICE_ORDERS_API: "/customer/{userId}/serviceOrder",
      PATCH_UPDATE_TAX_NUMBER: "/customer/{userId}",
      GET_ORDER_DETAILS:"/serviceOrder/{serviceOrderId}",
      GET_ORDER_DOCUMENTS:"/serviceOrder/{serviceOrderId}/documents ",
      UPLOAD_ORDER_DOCUMENT:"/serviceOrder/{serviceOrderId}/document/{documentType} ",
      DELETE_ORDER_DOCUMENT:"/serviceOrder/{serviceOrderId}/document/{documentId}",
      ADD_VEHICLE_PHOTO:"/serviceOrder/{serviceOrderId}/photo ",
      GET_ORDER_AUDIT_TRAIL:"/serviceOrder/{serviceOrderId}/changesLog",
      CHANGE_ORDER_STATUS:"/serviceOrder/{serviceOrderId}/status/{newStatus}",
      SET_SERVICE_PROVIDER_ON_THE_WAY:"/serviceOrder/{serviceOrderId}/notification/SERVICE_PROVIDER_ON_THE_WAY",
      PAY_ORDER_SERVICE_DUES_API:"/serviceOrder/{serviceOrderId}/pay",
      GET_POSSIBLE_ORDER_SERVICE_PROVIDER:"/serviceOrder/{serviceOrderId}/availableProviders",
      GET_SERVICE_DATE_OPTIONS:"/serviceOrder/{serviceOrderId}/serviceDateOptions",
      POST_SERVICE_TIME_OPTIONS:"/serviceOrder/{serviceOrderId}/serviceTimeOptions",
      PATCH_SERVICE_DATE_TIME:"/serviceOrder/{serviceOrderId}/serviceDateTime",
      ADD_OEDER_SERVICE_PROVIDER:"/serviceOrder/{serviceOrderId}/serviceProvider/{providerUserId}",
      VALIDATE_BASKET: "/serviceOrder/validate",
      GET_PAYMENT_TRANSACTION_DETAILS:"/payment/transaction/{paymentTransactionId}",
      GET_INVOICE_DETAILS:"/invoices/{invoiceId}",
      GET_INVOICE_CHANGES_LOG: "/invoices/{invoiceId}/changesLog",
      GET_INVOICE_PAYMENT_OPTIONS: "/invoices/{invoiceId}/paymentOption",
      POST_INVOICE_STATISTICS: "/invoices/statistics",
      POST_PAYMENT_STATISTICS: "/payment/statistics",
      PATCH_UPDATE_INVOICE_STATUS: "/invoices/{invoiceId}/pay", 

      GET_VEHICLE_DETAILS:"/fleet/{serviceVehicleId}",
      GET_TRACKING_VEHICLE_DETAILS:"/fleet/{serviceVehicleId}/liveTracking",
      EXECUTE_VEHICLE_COMMAND:"/fleet/{serviceVehicleId}/liveTracking/{command} ",
      GET_POSSIBLE_VEHICLE_SERVICE_PROVIDER:"/fleet/{serviceVehicleId}/serviceProvider",
      ADD_VEHICLE_SERVICE_PROVIDER:"/fleet/{serviceVehicleId}/serviceProvider/{serviceproviderId}?primaryServiceProvider=true",
      DELETE_VEHICLE_SERVICE_PROVIDER:"/fleet/{serviceVehicleId}/serviceProvider/{serviceproviderId}",
      GET_POSSIBLE_VEHICLE_ASSISTANT_SERVICE_PROVIDER:"/fleet/{ServiceVehicleId}/assistantServiceProvider",
      ADD_VEHICLE_ASSISTANT_SERVICE_PROVIDER:"/fleet/{serviceVehicleId}/assistantServiceProvider/{assistantId}",
      DELETE_VEHICLE_ASSISTANT_SERVICE_PROVIDER:"/fleet/{serviceVehicleId}/assistantServiceProvider/{assistantId}",
      ADD_VEHICLE:"/fleet",
      EDIT_VEHICLE:"/fleet/{serviceVehicleId}",
      LOOKUPS_VEHCILE_MAKES:"/lookups/vehiclesMake",
      LOOKUPS_VEHCILE_MODELS:"/lookups/vehiclesModel",
      GENERATE_LOCATION_URL:"/fleet/{serviceVehicleId}/liveTracking",
      LOAD_LIVE_TRACKING_INFO: "/fleet/liveTracking/{token}",

      CHANGE_VEHICLE_STATUS_API:"/fleet/{serviceVehicleId}/status/{serviceVehicleStatus}",
      GET_SERVICE_CATEGORIES_BY_FULLFILL_OPTION:"/serviceOrder/checkout/serviceCatalog/categories?page={page}&size={size}&serviceFulfillOption={serviceFulfillOption}",
      GET_SERVICE_CATEGORIES_BY_ORDER_ID:"/serviceOrder/{serviceOrderId}/lineItem/services",
      GET_SERVICES_BY_CATEGORY:"/serviceOrder/checkout/serviceCatalog/categories/{categoryId}/services",
      // GET_SERVICE_CATALOG:"/service/catalog",
      // GET_SERVICE_CATALOG_ONSITE:"/service/catalog?onSiteServiceCatalog=true",
      GET_VEHICLE_MODELS:"/lookups/vehiclesModel",
      POST_CALCULATE_ORDER_PRICE:"/serviceOrder/price",
      LOAD_ORDER_SUBSCRIPTIONS:"/serviceOrder/checkout/subscriptions",
      ADD_NEW_SERVICE_ORDER:"/serviceOrder",
      GET_CUSTOMER_VEHICLES:"/customer/{customerId}/vehicles",
      ADD_CUSTOMER_VEHICLE:"/customer/{customerId}/vehicle",
      CANCEL_SERVICE_ORDER:"/serviceOrder/{serviceOrderId}",
      CLOSE_SERVICE_ORDER:"/serviceOrder/{serviceOrderId}/status/COMPLETED",
      REVERSE_INVOICE:"/invoices/{invoiceId}/creditNote",
      MARK_SERVICE_EXECUTION_DONE:"/serviceOrder/{orderId}/status/SERVICE_PROVIDED",
      UPDATE_SERVICE_LOCATION:"/serviceOrder/{serviceOrderId}/serviceLocation",
      ADD_SERVICE_ORDER_ITEM:"/serviceOrder/{serviceOrderId}/lineItem",
      DELETE_SERVICE_ORDER_ITEM:"/serviceOrder/{serviceOrderId}/lineItem/{lineItemId}",
      GET_SERVICEE_DETAILS:"/serviceCatalog/service/{serviceId}",
      EDIT_SERVICEE_RATE:"/serviceCatalog/service/{serviceId}/serviceRate/{rateId}",
      GET_SERVICE_RATE_INDICATORS:"/serviceCatalog/service/{serviceId}/serviceRate/indicators",
      ADD_CUSTOM_SERVICE_ORDER:"/serviceOrder/{serviceOrderId}/customLineItem",
      DELETE_CUSTOM_SERVICE_ORDER:"/serviceOrder/{serviceOrderId}/customLineItem/{customLineItemId}",
      GET_LOGGED_USER_WALLET_API: "/user/wallet",
      GET_USER_WALLET_API: "/user/{userId}/wallet",
      GET_WALLET_DETAILS: "/cashBook/cashWallet/",
      POST_CASH_DEPOSIT: "/cashBook/{walletId}/depositRequest",
      PATCH_CONFIRM_CASH_DEPOSIT: "/cashBook/depositRequest/CONFIRMED",
      PATCH_REJECT_CASH_DEPOSIT: "/cashBook/depositRequest/REJECTED",
      GET_TIME_SLOTS: "/user/{userId}/serviceProvider/timeSlots",
      UPDATE_TIME_SLOT: "/user/{userId}/serviceProvider/timeSlot",
      CHECK_LOC_COVERAGE:"/serviceOrder/checkout/validate/serviceAreaCoverage",
      GET_AVAILABLE_SERVICE_DATE:"/serviceOrder/checkout/serviceDate",
      GET_AVAILABLE_SERVICE_TIME:"/serviceOrder/checkout/serviceTime",

      GET_AVAILABLE_SERVICE_DATE_CHANGES:"/serviceOrder/{serviceOrderId}/serviceLocation/serviceDateOptions",
      GET_AVAILABLE_SERVICE_TIME_CHANGES:"/serviceOrder/{serviceOrderId}/serviceLocation/serviceTimeOptions",

      GET_SERVICE_CATEGORIES:"/serviceCatalog/categories?page={page}&size={size}",
      UPDATE_SERVICE_CATEGORY:"/serviceCatalog/category/{categoryId}",
      UPDATE_SERVICE_CATEGORY_THUMNAIL:"/serviceCatalog/category/{categoryId}/thumbnail",
      CREATE_SERVICE_CATEGORY:"/serviceCatalog/category" , 
      UPDATE_SERVICE_IMG:"/serviceCatalog/service/{serviceId}/thumbnail" , 
      UPDATE_SERVICE:"/serviceCatalog/service/{serviceId}",
      UPDATE_SERVICE_CHANNEL:"/serviceCatalog/service/{serviceId}/channels/{channelName}" , 
      DELETE_DISCOUNT:"/serviceCatalog/service/{serviceId}/discountTemplate/{discountTemplateId}" , 
      LOAD_DISCOUNTS:"/serviceCatalog/discountTemplate?page={page}&size={size}",
      UPDATE_DISCOUNT_TEMPLATE:"/serviceCatalog/discountTemplate/{id} ",
      ATTACH_DISCOUNT:"/serviceCatalog/service/{serviceId}/discountTemplate/{discountTemplateId}",
      ADD_NEW_SERVICE_RATE:"/serviceCatalog/service/{serviceId}/serviceRate" , 
      CHANGE_SERVICE_STATUS:"/serviceCatalog/service/{serviceId}/status/{serviceStatus}" , 
      CREATE_DISCOUNT:"/serviceCatalog/discountTemplate ",
      LOAD_DISCOUNT_TYPES:"/lookups/enums/i18n/DiscountType" , 
      LOAD_DISCOUNT_DEDUCTION_TYPES:"/lookups/enums/i18n/DiscountDeductionType" , 
      LOAD_SERVICE_AREA:"/serviceArea/lookup?status=IN_COVERAGE",
      SERVICE_AREAS: "/serviceArea",
      GET_SERVICE_AREA_SERVICE_PROVIDERS: "/serviceArea/{serviceAreaId}/serviceProviders",
      UPDATE_SERVICE_AREA_DETAILS: "/serviceArea/{serviceAreaId}",
      UPDATE_SERVICE_AREA_STATUS: "/serviceArea/{serviceAreaId}/status/{status}",
      DELETE_SERVICE_AREA: "/serviceArea/{serviceAreaId}",
      UPDATE_OPERATION_TIME: "/serviceArea/{serviceAreaId}/operationHours/{recordId}",
      ADD_OPERATION_TIME: "/serviceArea/{serviceAreaId}/operationHours",

      GET_STATION_DETAILS:"/stations/{stationId}",
      CHANGE_STATION_STATUS_API:"/stations/{stationId}/status/{stationStatus}",
      GET_STATION_AUDIT_TRAIL:"/stations/{stationId}/changesLog",

      GET_STATION_USERS:"/stations/{stationId}/users",
      GET_STATION_SERVICE_ORDER:"/stations/{stationId}/serviceOrders",
      GET_STATION_STATISTICS_CARD:"/stations/{stationId}/statisticsCard",
      REMOVE_USER_STATION:"/stations/{stationId}/users/{userId}",
      GET_POSSIBLE_STATION_SUPERVISORS:"/stations/{stationId}/supervisor",
      GET_POSSIBLE_STATION_ATTENDANTS:"/stations/{stationId}/attendant",

      ADD_STATION_SUPERVISOR:"/stations/{stationId}/supervisor/{supervisorId}",
      ADD_STATION_ATTENDANT:"/stations/{stationId}/attendant/{attendantId}",
      EDIT_STATION:"/stations/{stationId}",
      ADD_STATION:"/stations"      ,
      GET_SERVICE_RATE_CLASSES:"/lookups/enums/i18n/ServiceRateClass" , 
      CREATE_SERVICE : "/serviceCatalog/service",
      GET_CONFIGURATIONS:"/configurations{qs}",
      EDIT_CONFIGURATIONS:"/configurations/{configId}",
      POINT_OF_SALE_SERVICE_URL: "/pos",
      POINT_OF_SALE_DETAILS_URL: "/pos/{pointOfSellId}",
      GET_POINT_OF_SALE_LOOKUPS_URL: "/pos/lookups",
      GET_POS_POSSIBLE_SERVICE_PROVIDERS: "/lookups/enums/i18n/PosServiceProvider",
      POST_LOAD_POSSIBLE_USERS_FOR_POINT_OF_SALE_ASSIGNMENT: "/pos/{pointOfSellId}/assignedUser",
      PATCH_ASSIGN_USER_TO_POINT_OF_SALE: "/pos/{pointOfSellId}/assignedUser/{userId}",
      GET_NATIONALITIES:"/lookups/nationalities{qs}",
      POST_NATIONALITY:"/lookups/nationality",
      PATCH_NATIONALITY:"/lookups/nationality/{nationalityId}",
      GET_MAKE_MODEL:"/lookups/vehiclesMakes{qs}",
      POST_MAKE_MODEL:"/lookups/vehiclesMake",
      PATCH_MAKE_MODEL:"/lookups/vehiclesMake/{makeId}",
      POST_MODEL:"/lookups/vehiclesMake/{makeId}/vehiclesModel",
      PATCH_MODEL:"/lookups/vehiclesModel/{modelId}",
      LOOKUP_MAKE_MODEL_SIZE: "/lookups/enums/i18n/VehicleSize",
      LOOKUP_MAKE_MODEL_TYPE: "/lookups/enums/i18n/VehicleType",

      LOOKUP_SERVICES: "/serviceCatalog/lookup",
      LOOKUP_SERVICE_AREAS: "/serviceArea/lookup",

      ADD_ANNOUNCEMENT:"/announcement",
      EDIT_ANNOUNCEMENT:"/announcement/{announcementId}",
      CANCEL_ANNOUNCEMENT:"/announcement/{announcementId}",

      ADD_COUPON:"/coupons",
      EDIT_COUPON:"/coupons/{couponId}",
      GET_COUPON_TRANSACTIONS:"/coupons/{couponId}/CouponTransactions",
      DEACTIVATE_COUPON:"/coupons/{couponId}/status/INACTIVE",
      ACTIVATE_COUPON:"/coupons/{couponId}/status/ACTIVE",
      
      POST_LOAD_SERVICE_OPERATION_OPTIONS: "/serviceCatalog/service/{serviceId}/operationOptions/lookups",
      PATCH_UPDATE_SERVICE_OPERATION_OPTIONS_COORDINATES: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/area/coordinates",
      PATCH_UPDATE_SERVICE_OPERATION_OPTIONS_STATUS: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/status/{status}",
      PATCH_UPDATE_SERVICE_OPERATION_OPTIONS_DETAILS: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}",
      POST_ADD_SERVICE_OPERATION_OPTION: "/serviceCatalog/service/{serviceId}/operationOption",
      POST_ADD_SERVICE_OPERATION_OPTION_TIME: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/operationHours",
      PATCH_UPDATE_SERVICE_OPERATION_OPTION_TIME: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/operationHours/{recordId}",
      DELETE_SERVICE_OPERATION_OPTION_TIME: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/operationHours/{recordId}",
      GET_LOAD_POSSIBLE_SERVICE_PROVIDERS_FOR_OPERATION_OPTION_ASSIGNMENT: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/serviceProviders?currentProviders=false&page={currentPage}&size={currentSize}",
      POST_ASSIGN_SERVICE_PROVIDER_TO_OPERATION_OPTION: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/serviceProviders/{serviceProviderId}",
      DELETE_SERVICE_PROVIDER_ASSIGNMENT_TO_OPERATION_OPTION: "/serviceCatalog/service/{serviceId}/operationOption/{optionId}/serviceProviders/{serviceProviderId}",

      GET_LOAD_POSSIBLE_EFFECTIVE_SPAN_OPTIONS_FOR_BUNDLE: "/lookups/enums/i18n/ServiceBundleEffectiveSpan",
      GET_LOAD_POSSIBLE_BUNDLE_RATE_CLASS: "/lookups/enums/i18n/BundleRateClass",
      GET_LOAD_POSSIBLE_VEHICLE_MODEL_FOR_VEHICLE_MODEL_RATE_CLASS : "/lookups/vehiclesModel/values",
      GET_LOAD_POSSIBLE_SUBSCRIPTION_CHANNELS: "/lookups/enums/i18n/SourceChannel",
      GET_LOAD_SERVICES_OPTIONS_TO_BE_ADDED_IN_THE_BUNDLE: "/serviceCatalog/lookup",
      POST_ADD_NEW_SERVICE_BUNDLE: "/serviceBundle",
      GET_LOAD_SERVICE_BUNDLE_DETAILS: "/serviceBundle/{serviceBundleId}",
      GET_LOAD_SERVICE_BUNDLE_SUBSCRIPTIONS: "/serviceBundle/{serviceBundleId}/subscriptions?page={page}&size={size}",
      PATCH_UPDATE_BUNDLE_STATUS: "/serviceBundle/{serviceBundleId}/status/{status}",
      POST_UPDATE_BUNDLE_THUMBNAIL_IMAGE: "/serviceBundle/{serviceBundleId}/thumbnail",
      PATCH_UPDATE_SERVICE_BUNDLE: "/serviceBundle/{serviceBundleId}",
      POST_ADD_SERVICE_TO_BUNDLE: "/serviceBundle/{serviceBundleId}/service",
      DELETE_SERVICE_FROM_BUNDLE: "/serviceBundle/{serviceBundleId}/service/{serviceItemId}",
      DELETE_RATE_FROM_BUNDLE: "/serviceBundle/{serviceBundleId}/bundleRate/{bundleRateId}",
      PATCH_UPDATE_RATE_BUNDLE: "/serviceBundle/{serviceBundleId}/bundleRate/{bundleRateId}",
      PATCH_UPDATE_SERVICE_BUNDLE_QTY: "/serviceBundle/{serviceBundleId}/service/{serviceItemId}",
      POST_ADD_RATE_BUNDLE: "/serviceBundle/{serviceBundleId}/bundleRate",
      POST_LOAD_POSSIBLE_BUNDLES_FOR_CUSTOMER: "/subscription/serviceBundle/options?customerId={customerId}",
      GET_POSSIBLE_SUBSCRIPTION_TYPES: "/lookups/enums/i18n/BundleSubscriptionType",
      GET_POSSIBLE_COMMITMENTS: "/lookups/enums/i18n/SubscriptionCommitment",
      POST_SUBMIT_SUBSCRIPTION: "/subscription",
      GET_SUBSCRIPTION_DETAILS: "/subscription/{subscriptionId}",
      GET_SUBSCRIPTION_ATTACHMENT: "/subscription/{subscriptionId}/documents",
      GET_SUBSCRIPTION_PAYMENT_TRANSACTION_HISTORY: "/subscription/{subscriptionId}/paymentTransactions?page={page}&size={size}",
      GET_SUBSCRIPTION_INVOICE_HISTORY: "/subscription/{subscriptionId}/invoices?page={page}&size={size}",
      GET_SUBSCRIPTION_SERVICE_ORDER_HISTORY: "/subscription/{subscriptionId}/serviceOrders?page={page}&size={size}",
      DELETE_SUBSCRIPTION_ATTACHMENT: "/subscription/{subscriptionId}/document/{documentId}",
      GET_DOWNLOAD_ATTACHMENT: "/subscription/{subscriptionId}/document/{documentId}",
      POST_UPLOAD_ATTACHMENT: "/subscription/{subscriptionId}/document/SUBSCRIPTION_DOCS",
      ACTIVATE_SUBSCRIPTION: "/subscription/{subscriptionId}/status/ACTIVE?reason=Activation",
      POST_COLLECT_PREPAID_SUBSCRIPTION_PAYMENT:"/subscription/{subscriptionId}/pay",
      GET_SPAN_SERVICE_HISTORY: "/subscription/{subscriptionId}/usageSpans/{spanId}/serviceOrders?page={page}&size={size}",
      GET_USAGE_SPAN_HISTORY: "/subscription/{subscriptionId}/usageSpans?page={page}&size={size}",
      GET_LOAD_CUSTOMER_SUBSCRIPTION_HISTORY: "/customer/{customerUserId}/subscription",
      POST_ADD_STORE_HOUSE:"/storehouse" , 
      GET_STORE_HOUSE_DETAILS:"/storehouse/{storeHouseId}" ,
      UPDATE_STORE_HOUSE_DETAILS:"/storehouse/{storeHouseId}" ,
      GET_STORE_HOUSE_ITEMS:"/storehouse/{storehouseId}/items" ,  
      ADD_ITEM_TO_STORE : "/storehouse/{storehouseId}/item" , 
      GET_MEASURE_UNITS : "/lookups/enums/i18n/QuantityUnit" ,
      GET_TRANSACTION_TYPES : "/lookups/enums/i18n/InventoryTransactionType" ,
      POST_STORE_HOUSE_TX:"/storehouse/{storeHouseId}/transactions" ,  
      SEARCH_ITEMS_IN_STORE : "/storehouse/{storehouseId}/items?current={current}" , 
      POST_ADD_TRANSACTION_TO_ITEMS : "/storehouse/{storehouseId}/transaction" ,
      POST_ADD_TRANSACTION : "/stockItemInventory"  , 
      POST_ADD_ITEM_NEW : "/stockItem" ,
      ITEM_LOAD_POSSIBLE_STORE : "/stockItem/{itemId}/storehouse?current=false" , 
      ITEM_ADD_ITEM_TO_STORE : "/stockItem/{itemId}/inventory" ,
      GET_ITEM_DETAILS : "/stockItem/{itemId}" , 
      GET_ITEM_AVAILABILTY:"/stockItem/{itemId}/inventory" , 
      POST_GET_ITEM_TRANSACTION:"/stockItem/{itemId}/transactions" , 
      UPDATE_STOCK_ITEM:"/stockItem/{itemId}" ,   
      PATCH_MARK_SERVICE_ADDON: "/serviceCatalog/service/{serviceId}/addOnService/{status}" ,
      ENABLE_CASH_PAYMENT: "/serviceCatalog/service/{serviceId}/acceptCash/true" ,
      DISABLE_CASH_PAYMENT: "/serviceCatalog/service/{serviceId}/acceptCash/false" , 
      ADD_SERVICE_COUPON : "/serviceOrder/{serviceOrderId}/coupon" , 
      CASHBOOK_STATISTICS: "/cashBook/statistics",
      APPROVE_CANCEL_REQUEST: "/subscription/{subscriptionId}/approveCancelRequest/{refundAmount}",
      CUSTOMER_ADDERESS: "/customer/{customerId}/address",
      BUNDLE_AREA_COVERAGE: "/serviceBundle/serviceOrder/bundle/coverage",
      ENABLE_WAIT_REQUEST: "/serviceCatalog/service/{serviceId}/waitRequestEnabled/true",
      DISABLE_WAIT_REQUEST: "/serviceCatalog/service/{serviceId}/waitRequestEnabled/false",
    }
  };

  export enum SocketClientState {
    ATTEMPTING,
    CONNECTED,
  }
