import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent {
  @Input()  title: string;
  @Input()  message: string;
  @Output() confirmEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() declineEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public modalRef: BsModalRef) {


  }

  confirm(): void {
    this.confirmEvent.emit();
    this.modalRef.hide();
  }

  decline(): void {
    this.declineEvent.emit();
    this.modalRef.hide();
  }


  
}
