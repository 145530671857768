import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.services';

@Component({
  selector: 'app-cash-deposit-request',
  templateUrl: './cash-deposit-request.component.html',
  styleUrls: ['./cash-deposit-request.component.scss']
})
export class CashDepositRequestComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private commonService: CommonService) { }

  confirmMessageData: any;
  token: any;
  notValidToken: boolean;
  async ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    if (!this.token) {
      this.notValidToken = true;
      return;
    }

    try {
      let response = await this.commonService.getDepositConfirmationMessage(this.token);
      this.confirmMessageData = {
        "cashWalletOwnerName": response.cashWalletOwnerName,
        "depositionTransactionId": response.depositionTransactionId,
        "amount": response.amount,
        "requestedBy": response.requestedBy,
        "bankDeposition": response.bankDeposition,
        "bankDepositionRef": response.bankDepositionRef,
      };
    } catch (e) {
      this.confirmMessageData = {
        "errorMsgEn": e.error.errorMsgEn,
        "errorMsgAr": e.error.errorMsgAr,
      };
    }
  }

  async onConfirm() {
    try {
      let response = await this.commonService.patchConfirmDeposit(this.token);
      this.router.navigate(["/user/login"]);
    } catch (e) {
      this.confirmMessageData = {
        "errorMsgEn": e.error.errorMsgEn,
        "errorMsgAr": e.error.errorMsgAr,
      };
    }
  }
  async onReject() {
    try {
      let response = await this.commonService.patchRejectDeposit(this.token);
      this.router.navigate(["/user/login"]);
    } catch (e) {
      this.confirmMessageData = {
        "errorMsgEn": e.error.errorMsgEn,
        "errorMsgAr": e.error.errorMsgAr,
      };
    }
  }
}
