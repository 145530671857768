import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from '@ngx-translate/core'; 
import { RouterModule } from '@angular/router';
import { HasPrviageDirective } from "src/app/directives/hasPrivilageDirective";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import { ModalConfirmComponent } from '../views/modal-confirm/modal-confirm.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortablejsModule } from 'ngx-sortablejs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FullCalendarModule } from "@fullcalendar/angular"; // for FullCalendar!
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import { BootstrapModule } from '../components/bootstrap/bootstrap.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ListFilterComponent } from '../components/list-filter/list-filter.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule   } from '@danielmoncada/angular-datetime-picker';

 
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

 
@NgModule({
  declarations: [ ErrorComponent,ListFilterComponent,HasPrviageDirective, UnauthorizedComponent,ModalConfirmComponent],
  imports: [
    
    BsDropdownModule.forRoot(),
    NgxDatatableModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SortablejsModule,
    RouterModule,
    PaginationModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    
    BsDatepickerModule.forRoot(),
     PerfectScrollbarModule,
    BootstrapModule , 
    CollapseModule.forRoot(),
  
   ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    FormsModule,
    BsDatepickerModule,
    CollapseModule,
    NgxDatatableModule,
    NgSelectModule,
    HasPrviageDirective,
    ReactiveFormsModule,
    ErrorComponent,
    ListFilterComponent , 
    UnauthorizedComponent,
    ModalConfirmComponent,
    TranslateModule,
    CommonModule,
    SortablejsModule,
    BsDropdownModule,
    PaginationModule ,
   ],
   
})
export class SharedModule {}
 