import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { constants } from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient) {}
  downloadFile(fileURL: any): Observable<any> {
    let res: any = this.http.get(fileURL, { responseType: "blob" });
    return res;
  }

  downloadFileById(documentId: string): Observable<any> {
    const url = environment.baseURL + constants.API.DOWNLOAD_DOCUMENT_BY_ID_API
    let res: any = this.http.get(url.replace("{documentID}", documentId), { responseType: "blob" });
    return res;
  }
  async searchAPI(body: any): Promise<any> {
    const url = environment.baseURL + constants.API.SEARCH_API;
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }


  exportSearchAPI(body: any): Observable<any> {
    const url = environment.baseURL + constants.API.EXPORT_FILTER;
    let res: any = this.http.post(url, body, { responseType: "blob" });
    return res;
  }
   async getWalletDetails(givenUrl: any, pageNumber: number, size: number): Promise<any> {
    const url = environment.baseURL + givenUrl + "?page=" + pageNumber + "&size=" + size;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getDepositConfirmationMessage(token: string): Promise<any> {
    const url = environment.baseURL + constants.API.CLAIM_TOKEN_API + "/" + token;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async patchConfirmDeposit(token: string): Promise<any> {
    const url = environment.baseURL + constants.API.PATCH_CONFIRM_CASH_DEPOSIT;
    let res: any = await this.http.patch(url, { 'token': token }).toPromise();
    return res;
  }

  async patchRejectDeposit(token: string): Promise<any> {
    const url = environment.baseURL + constants.API.PATCH_REJECT_CASH_DEPOSIT;
    let res: any = await this.http.patch(url, { 'token': token }).toPromise();
    return res;
  }

  async cashDeposit(body: any, walletId: string): Promise<any> {
    const url = environment.baseURL + constants.API.POST_CASH_DEPOSIT;
    let res: any = await this.http.post(url.replace("{walletId}", walletId), body).toPromise();
    return res;
  }

  async getTimeSlot(date: string, userId:number): Promise<any> {
    const body = {"value": date};
    const url = environment.baseURL + constants.API.GET_TIME_SLOTS;
    let res: any = await this.http.post(url.replace("{userId}", userId+""), body).toPromise();
    return res;
  }
  async updateTimeSlot(body: any, userId:number): Promise<any> {
    const url = environment.baseURL + constants.API.UPDATE_TIME_SLOT;
    let res: any = await this.http.post(url.replace("{userId}", userId+""), body).toPromise();
    return res;
  }

  async loadPointOfSale(pageNumber: number, size: number): Promise<any> {
    const url = environment.baseURL + constants.API.POINT_OF_SALE_SERVICE_URL + "?page=" + pageNumber + "&size=" + size;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getPointOfSaleDetails(id: any): Promise<any> {
    const url = environment.baseURL + constants.API.POINT_OF_SALE_DETAILS_URL.replace('{pointOfSellId}', id);
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getPosServiceProviders(): Promise<any> {
    const url = environment.baseURL + constants.API.GET_POS_POSSIBLE_SERVICE_PROVIDERS;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async addNewPointOfSale(body: any): Promise<any> {
    const url = environment.baseURL + constants.API.POINT_OF_SALE_SERVICE_URL;
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }
  async editPointOfSale(body: any, id: any): Promise<any> {
    const url = environment.baseURL + constants.API.POINT_OF_SALE_DETAILS_URL.replace('{pointOfSellId}', id);
    let res: any = await this.http.patch(url, body).toPromise();
    return res;
  }

  async getPossibleUsersForPosAssignment(body: any, id: any): Promise<any> {
    const url = environment.baseURL + constants.API.POST_LOAD_POSSIBLE_USERS_FOR_POINT_OF_SALE_ASSIGNMENT.replace('{pointOfSellId}', id);
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }

  async assignUserToPos(pointOfSellId: any, userId: any): Promise<any> {
    const url = environment.baseURL + constants.API.PATCH_ASSIGN_USER_TO_POINT_OF_SALE.replace('{pointOfSellId}', pointOfSellId).replace('{userId}', userId);
    let res: any = await this.http.patch(url, {}).toPromise();
    return res;
  }

  async getInvoiceStatistics(body: any): Promise<any> {
    const url = environment.baseURL + constants.API.POST_INVOICE_STATISTICS;
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }

  async getPaymentsStatistics(body: any): Promise<any> {
    const url = environment.baseURL + constants.API.POST_PAYMENT_STATISTICS;
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }

  async changeInvoiceStatusToPaid(invoiceId: any, body: any): Promise<any> {
    const url = environment.baseURL + constants.API.PATCH_UPDATE_INVOICE_STATUS.replace('{invoiceId}', invoiceId);
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }

  async getInvoiceChangesLog(invoiceId: string): Promise<any> {
    const url = environment.baseURL + constants.API.GET_INVOICE_CHANGES_LOG.replace('{invoiceId}', invoiceId);
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getInvoicePaymentOptions(invoiceId: string): Promise<any> {
    const url = environment.baseURL + constants.API.GET_INVOICE_PAYMENT_OPTIONS.replace('{invoiceId}', invoiceId);
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getPointOfSaleLookups(): Promise<any> {
    const url = environment.baseURL + constants.API.GET_POINT_OF_SALE_LOOKUPS_URL;
    let res: any = await this.http.get(url).toPromise();
    return res;
  }

  async getCashbookStatistics(body: any): Promise<any> {
    const url = environment.baseURL + constants.API.CASHBOOK_STATISTICS;
    let res: any = await this.http.post(url, body).toPromise();
    return res;
  }
}
