import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { constants } from '../constants/constants';

import { environment } from '../../environments/environment';
import { User } from '../models/user.model';

@Injectable({
	providedIn: 'root'
})
export class ProfileService  {
	constructor(private http: HttpClient) {

	}

	async updateUserProfile(user:any): Promise<any> {
		const url = environment.baseURL + constants.API.UPDATE_PROFILE_API;
		let res:any = await this.http.patch(url,user).toPromise();
		return res;
	}

	async getUserPhoto(): Promise<any> {
		const url = environment.baseURL + constants.API.GET_USER_PHOTO_API;
		let res:any = await this.http.get(url,{ responseType: 'blob' }).toPromise();
		return res;
	}

	async getUserPhotoByKey( imgKey: String): Promise<any> {
		const url = environment.baseURL +  imgKey;
		let res:any = await this.http.get(url,{ responseType: 'blob' }).toPromise();
		return res;
	}


	async uploadUserPhoto(fileToUpload:File): Promise<any> {
		const url = environment.baseURL + constants.API.UPLOAD_USER_PHOTO_API;
		try{
			const formData: FormData = new FormData();
			formData.append('photo', fileToUpload, fileToUpload.name);
			let res:any = await this.http.patch(url,formData,{ responseType: 'blob' }).toPromise();
			return res;
		}catch(e){
			console.log(e);
		}
	}

	async startForgetPassword(email:string): Promise<any> {
		const url = environment.baseURL + constants.API.FORGET_PASSWORD_API;
		let res:any = await this.http.post(url,{'userIdentifier':email}).toPromise();
		return res;
	}

	async resetForgetPassword(userIdentifier:string , password:string,confirmedPassword:string,otp:string): Promise<any> {
		const url = environment.baseURL + constants.API.RESET_PASSWORD_API ;
		const body = new HttpParams()
		.set("password", password)
        .set("confirmedPassword", confirmedPassword)
		.set("userIdentifier",userIdentifier )
		.set("otp" , otp)
		let res:any = await this.http.post(url,body.toString(),{
			headers: new HttpHeaders()
			  .set('Content-Type', 'application/x-www-form-urlencoded')
		  }).toPromise();
		return res;
	}

	async changePassword(newPassword:string,oldPassword:string): Promise<any> {
		const url = environment.baseURL + constants.API.CHANGE_PASSWORD_API;
		const body = new HttpParams()
		.set("newPassword", newPassword)
        .set("oldPassword", oldPassword);
		let res:any = await this.http.patch(url,body.toString(),{
			headers: new HttpHeaders()
			  .set('Content-Type', 'application/x-www-form-urlencoded')
		  }).toPromise();
		return res;
	}

	async claimToken(token:string): Promise<any> {
		const url = environment.baseURL + constants.API.CLAIM_TOKEN_API+'/'+token;
		let res:any = await this.http.get(url).toPromise();
		return res;
	}

}


