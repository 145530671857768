import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionDataService } from '../../app/services/session-data.service';
import { environment } from '../../environments/environment';
import { constants } from '../constants/constants';
import { User } from '../models/user.model';


@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private sessionDataService: SessionDataService,
		private http: HttpClient
	) {
	}

	canActivate(nextRoute: ActivatedRouteSnapshot, nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		let self = this;
		let nextUrl = nextState.url;
		let currentUrl = this.router.url;
		return new Promise<boolean>((resolve, reject) => {
			if(this.sessionDataService.getAccessToken()){
				if(nextRoute.data.privileges && nextRoute.data.privileges.length > 0){
					if (nextRoute.data.privileges.indexOf(this.sessionDataService.getcurrentUser().authoritiesSet[0]) === -1) {
						// role not authorised so redirect to home page
						self.router.navigate([environment.adminRoot]);
						resolve(true);
					}
				}
				//authorised so return true
				resolve(true);
			}else{
				self.router.navigate(['/user/login'],{queryParams: {returnUrl: nextState.url}});
				resolve(false);
			}
		});
	}

	async login(email: string, password: string, isRememberMe: boolean): Promise<any> {
		  
		const url = environment.baseURL + constants.API.LOGIN_API;
		const body = new HttpParams()
		.set('userIdentifier', email)
		.set('password', password)
		localStorage.setItem(constants.PRISISTED_KEYS.REMEMBER_ME, JSON.stringify(isRememberMe));

		let res:any = await this.http.post(url,body.toString(),
		{
		  headers: new HttpHeaders()
			.set('Content-Type', 'application/x-www-form-urlencoded')
		}).toPromise();
		this.sessionDataService.setAccessToken(res.accessToken);
	}

	async verifyOTP(otpString: string  , requestId:string): Promise<any> {
		const url = environment.baseURL + constants.API.VERIFY_OTP;
		let res:any = await this.http.post(url.replace("{regReqId}", requestId)
		.replace("{otpCode}", otpString), '').toPromise();
		this.sessionDataService.setAccessToken(res.accessToken);
	}


	async activateUserByMail(token: string): Promise<any> {
		const url = environment.baseURL + constants.API.MAIL_USER_ACTIVATION_API+'?token='+token;
		let res: any = await this.http.post(url,{}).toPromise();
		this.sessionDataService.setAccessToken(res.accessToken);
	}

	async getCurrentUserProfile(): Promise<any> {
		const url = environment.baseURL + constants.API.GET_PROFILE_API;
		let res:any = await this.http.get(url,{}).toPromise();
		let currentUser:User = {
			...res,
			firstName:res.fullName.split(" ")[0]
		}
		this.sessionDataService.setcurrentUser(currentUser);
	}

	async logout(): Promise<any> {
		localStorage.removeItem(constants.PRISISTED_KEYS.ACCESS_TOKEN);
		sessionStorage.removeItem(constants.PRISISTED_KEYS.ACCESS_TOKEN);
		localStorage.removeItem(constants.PRISISTED_KEYS.REMEMBER_ME);
		this.sessionDataService.setcurrentUser(null);
		this.router.navigate(['/user/login']);
	}

}


