<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button
    type="button"
    class="close"
    (click)="modalRef.hide()"
    aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" >
  <p [innerHTML]="message"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" data-dismiss="modal"
  (click)="decline()">{{ 'modal.no' | translate  }}</button>

  <button type="submit" class="btn btn-danger" (click)="confirm()">
    {{ 'modal.yes' | translate  }}
  </button>
</div>