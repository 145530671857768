<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>


  

    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>

  </div>

  <a class="navbar-logo" [routerLink]="adminRoot" >
    <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <div class="position-relative d-none d-sm-inline-block" *appHasPrivilage="['ROLE_ADMIN','ROLE_OPS_MANAGER','ROLE_SUPERVISOR']">
        <div dropdown>
          <button dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic"
            id="iconMenuDropdown">
              <a [routerLink]="['/app/service-orders/new-order']" class="icon-menu-item">
                <i class="simple-icon-plus d-block"></i>
                <span>{{ "SHORT_CUTS.SERVICE_ORDERS.CREATE_NEW" | translate }}</span>
              </a>
  
              <a [routerLink]="['/app/customer-management']" class="icon-menu-item">
                <i class="iconsminds-mens d-block"></i>
                <span>{{ "SHORT_CUTS.USER_MANAGEMENT.CUSTOMERS" | translate }}</span>
              </a>
  
              <a [routerLink]="['/app/resources/fleet']" class="icon-menu-item">
                <i class="iconsminds-car d-block"></i>
                <span>{{ "SHORT_CUTS.RESOURCES.SERVICE_FLEET" | translate }}</span>
              </a>
  
              <a [routerLink]="['/app/resources/stations']" class="icon-menu-item">
                <i class="iconsminds-shop d-block"></i>
                <span>{{ "SHORT_CUTS.RESOURCES.SERVICE_STATIONS" | translate }}</span>
              </a> 
          </div>
        </div>
      </div> 

      <div class="position-relative d-inline-block">
      
        <div dropdown>
          <button id="button-basic" dropdownToggle type="button" id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">{{notifications.length}}</span>
          </button>
          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" id="notificationDropdown">
               
            <perfect-scrollbar>
              <div class="
                    dropdown-header
                    d-flex
                    align-items-center
                    justify-content-between
                  ">
              <p class="mb-0 font-weight-medium">
                {{ notifications.length }}
                {{ "LAYOUT.NEW_NOTIFICATIONS" | translate }}
              </p>
            </div>   
            <a href="" (click)="(false)" *ngFor="let notification of notifications; let i = index">
             
                <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                  <div class="pl-3">
                    <a href="javascript:;">
                      <p class="font-weight-bold mb-1">{{ notification.subjectEn }}</p>
                      <p style="word-break: break-word;" class="font-weight-medium mb-1"> {{ notification.summaryEn }}</p>
      
                    </a>
                  </div>
                </div>
              
            </a>
            <hr>
            <div class="
                    dropdown-footer
                    d-flex
                    align-items-center
                    justify-content-center
                  ">
      
              <a href="" (click)="(false)" routerLink="/app/notifications">{{
                "LAYOUT.VIEW_ALL" | translate
                }}</a>
            </div>
            </perfect-scrollbar>
            
          </div>
        </div>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name"> {{ currentUser?.fullName }}</span>
        <span>
          <img alt="Profile Picture" [src]="userImage" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" (click)="onAccountClick()">{{'user.Account' | translate}}</a>

        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">{{'user.log-out' | translate}}</a>
      </div>
    </div>

  </div>
</nav>
<simple-notifications></simple-notifications>
