export interface ILog {
    key: number;
    label: string;
    time: string;
    color: string;
}

const data: ILog[] = [
    {
        label: 'New user registiration',
        time: '14:12',
        color: 'border-theme-1',
        key: 0
    },
    {
        label: 'New sale: Soufflé',
        time: '13:20',
        color: 'border-theme-2',
        key: 1
    },
    {
        label: '14 products added',
        time: '12:55',
        color: 'border-danger',
        key: 2
    },
    {
        label: 'New sale: Napoleonshat',
        time: '12:44',
        color: 'border-theme-2',
        key: 3
    },
    {
        label: 'New sale: Cremeschnitte',
        time: '12:30',
        color: 'border-theme-2',
        key: 4
    },
    {
        label: 'New sale: Soufflé',
        time: '10:40',
        color: 'border-theme-2',
        key: 5
    },
    {
        label: '2 categories added',
        time: '10:20',
        color: 'border-danger',
        key: 6
    },
    {
        label: 'New sale: Chocolate Cake',
        time: '09:28',
        color: 'border-theme-2',
        key: 7
    },
    {
        label: 'New sale: Magdalena',
        time: '09:25',
        color: 'border-theme-2',
        key: 8
    },
    {
        label: 'New sale: Fat Rascal',
        time: '09:20',
        color: 'border-theme-2',
        key: 9
    },
    {
        label: 'New sale: Parkin',
        time: '09:10',
        color: 'border-theme-2',
        key: 10
    }
];

export default data;
