<div class="row">
  <div class="col-md-8" style="margin: 0;padding: 3%;">
    <a routerLink="./" (click)="clearFilters()" style="padding: .3em !important; margin: 3%; cursor: pointer;"
      class="btn btn-outline-primary mb-1">
      <i class="iconsminds-remove"></i> {{ 'filter.clear' | translate }}
    </a>
  </div>
  <div class="col-md-4" style="margin: 0;padding: 3%;text-align: center;">
    <a routerLink="./" (click)="changeCollapsed()" style="padding: .3em !important; margin: 3%; cursor: pointer;"
      class="btn btn-outline-danger m mb-1">
      <i class="iconsminds-close"></i>
    </a>
  </div>

</div>
<div class="filter-button-container" *ngIf="isFilterable && isHorizontalFilter">
  <button type="button" class="btn btn-primary d-lg-none" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
    <span class="feather icon-filter"></span>
    <span *ngIf="isCollapsed">{{ "COMMON.SHOW-FILTERS" | translate }} </span>
    <span *ngIf="!isCollapsed">{{ "COMMON.HIDE-FILTERS" | translate }} </span>

  </button>
</div>


<!--*******************************************************************************************************************************-->
<!--****************              Collapsed Accordion Filter Style               ************************************** ***********-->
<!--****************                Can be Used For Mobile View                   *************************************************-->
<!--*******************************************************************************************************************************-->
<div *ngIf="filterCollapseView" class="card mb-4"   id="collapseExample" [ngbCollapse]="isCollapsed">
  <div style="padding: 0;" class="card-body">

    <form [formGroup]="changeFilters">
      <div class="row mb-2 ml-2 mt-2" *ngIf="filterFields && filterFields.length > 0 ">
        <accordion style="width: 95%;">

          <div *ngFor="let item of filterFields; let id = index">

            <!--  Select Filter -->
            <accordion-group style="width: 95%;" #groupval
              *ngIf="(item.dataType === 'MULTI_SELECT' || item.dataType === 'SELECT'  )" [panelClass]="'border'"
              [isOpen]="item.filteredValues && item.filteredValues[0]?true:false">
              <div accordion-heading style="width:100%;">
                {{ translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr }}
                <i *ngIf="!groupval.isOpen" class="pull-right float-xs-right iconsminds-plus"
                  [ngClass]="{'glyphicon-chevron-down': groupval?.isOpen, 'glyphicon-chevron-up': !groupval?.isOpen}">
                  <i class="simple-icon-plus"></i></i>
                <i *ngIf="groupval.isOpen" class="pull-right float-xs-right iconsminds-plus"
                  [ngClass]="{'glyphicon-chevron-down': groupval?.isOpen, 'glyphicon-chevron-up': !groupval?.isOpen}">
                  <i class="simple-icon-minus"></i></i>
              </div>
              <div class="mb-2 mr-4" *ngIf="(item.dataType === 'MULTI_SELECT') && item.selectValues.length > 0">
                <div class="selectField form-group">
                  <ul style="column-count: 2;" class="list-unstyled mb-5">
                    <li *ngFor="let item2 of item.selectValues.slice(0,item.maxViewedOptionsCount) ; let i = index">
                      <div class="custom-control custom-checkbox mb-2">
                        <input type="checkbox" [checked]="item2.checked"
                          (change)="onCheckboxChanged(item2, item.fieldName , $event)" [value]="item2"
                          class="custom-control-input" id="{{item.fieldName + i}}">
                        <label class="custom-control-label" for="{{item.fieldName + i}}">
                          {{ item2.name }}
                        </label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>
                  </ul>
                </div>
              </div>
              <!--  Select Filter  -->
              <div class="mb-2 mr-4" *ngIf="(item.dataType === 'SELECT') && item.enumValues && item.selectValues.length > 0 ">
                <div class="selectField form-group">
                  <p class="text-muted text-small">
                    {{ translate.currentLang == 'en' ? item.placeHolderEn : item.placeHolderAr }}
                  </p>
                  <ul class="list-unstyled mb-5">
                    <li *ngFor="let item2 of item.selectValues.slice(0,item.maxViewedOptionsCount) ; let i = index">
                      <div class="custom-control custom-radio mb-2">
                        <input type="radio" [value]="item2.value" (change)="onCheckboxChange(item2 , item.fieldName )"
                          class="custom-control-input" formControlName="{{ item.fieldName }}" id="{{item.fieldName+i}}">
                        <label class="custom-control-label" for="{{item.fieldName + i}}">{{translate.currentLang ===
                          'en'? item2.valueEn : item2.valueAr }}</label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>
                  </ul>
                </div>
              </div>
              <div class="d-block d-md-inline-block position-relative" *ngIf="item.dataType === 'SELECT' && !item.enumValues && item.selectValues.length > 0">

                <div class="selectField form-group">
                  <p class="text-muted text-small">
                    {{ translate.currentLang == 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr }}
                  </p>
                  <ul class="list-unstyled mb-5">
                    <li *ngFor="let item2 of item.selectValues.slice(0,item.maxViewedOptionsCount) ; let i = index">
                      <div class="custom-control custom-radio mb-2">
                        <input type="radio" (change)="onCheckboxChange(item2, item.fieldName )" [name]="item.fieldName"
                          [value]="item2.optionValue" class="custom-control-input"
                          formControlName="{{ item.fieldName }}" [id]="item.fieldName + i">
                        <label class="custom-control-label" for="{{item.fieldName  +i}}">{{ item2.name }}</label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>
                  </ul>
                </div>
              </div>
            </accordion-group>
            <!--  Date Filter -->
            <div *ngIf="item.dataType === 'DATE'" class="input-group-prepend mr-3 mb-5">
              <div class="input-group-prepend">
                <span class="input-group-text"> <i class="iconsminds-calendar-4"> </i></span>
              </div>
              <input type="text" placeholder="{{
                translate.currentLang === 'en'
                  ? item.placeHolderEn
                  : item.placeHolderAr
              }}" class="form-control" formControlName="basicDate" bsDatepicker [container]="'html'"
                [bsConfig]="dateConfig">
            </div>
            <div class="mb-2 mr-4 " *ngIf="item.dataType === 'DATE_TIME'    "
              class="input-group-prepend mr-3 mt-3 mb-5">
              <div class="input-group-text">
                <i class="iconsminds-calendar-4"> </i>
              </div>
              <br/>
              <input name="dateSingle" 
                formControlName="{{ item.fieldName }}"
                [owlDateTime]="dateSingle" 
                placeholder="{{
                  translate.currentLang === 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr
                }}"
                [owlDateTimeTrigger]="dateSingle" 
                class="form-control" 
                [selectMode]="'single'" />
                <owl-date-time [firstDayOfWeek]="'1'" 
                #dateSingle></owl-date-time>
            </div>

            <div class="mb-2 mr-4" *ngIf="item.dataType === 'DOUBLE'   ">
              <div class="form-group">
                <input [id]="item.fieldName" type="text" class="form-control" formControlName="{{ item.fieldName }}"
                  type="text" [placeholder]="
                    translate.currentLang == 'en'
                      ? item.placeHolderEn
                      : item.placeHolderAr
                  " />
              </div>
            </div>
            <div class="mb-2 mr-4 mt-2" *ngIf="item.dataType === 'BOOLEAN'   ">
              <div class="form-group">
                <label class="form-check-label ml-4">
                  <input [id]="item.fieldName" class="form-check-input" formControlName="{{ item.fieldName }}"
                    type="checkbox" [placeholder]="item.fieldName" />
                  <i class="input-frame"></i>
                  {{
                  translate.currentLang == "en"
                  ? item.placeHolderEn
                  : item.placeHolderAr
                  }}
                </label>
              </div>
            </div>
          </div>
        </accordion>
      </div>
    </form>
  </div>
</div>
<!--*******************************************************************************************************************************-->
<!--****************                NON  Collapsed Filter Style               ************************************** ***********-->
<!--****************                  Can be Used For WEB View                   *************************************************-->
<!--*******************************************************************************************************************************-->


<div *ngIf="!filterCollapseView" class="card mb-4"   >
  <div   class="card-body">

    <!-- <div
        *ngIf="filterFields.length == 0"
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">{{ "COMMON.LOADING" | translate }}</span>
      </div> -->

    <form [formGroup]="changeFilters">
      <div class="row mb-2 ml-2 mt-2" *ngIf="filterFields && filterFields.length > 0 ">
       

          <div class="w-100" *ngFor="let item of filterFields; let id = index">

            <!--  Select Filter -->
   
           
              <div class="w-100 mb-2 mr-4" *ngIf="
                  (item.dataType === 'MULTI_SELECT') &&
                  item?.selectValues?.length > 0 
                ">
               <label style="display: block;font-weight: bold;">{{ translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr }}</label> 

                <div   class="selectField form-group">


                  <ul style="column-count: 1;" class="list-unstyled mb-5">

                    <li *ngFor="let item2 of item.selectValues | slice:0:item.maxViewedOptionsCount ; let i = index">
                      <div class="custom-control custom-checkbox mb-2">
                        <input type="checkbox" [checked]="item2.checked"
                          (change)="onCheckboxChanged(item2, item.fieldName , $event)" [value]="item2"
                          class="custom-control-input" id="{{item.fieldName + i}}">
                        <label class="custom-control-label" for="{{item.fieldName + i}}">
                          {{ item2.name }}
                        </label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>
                   </ul>

                </div>
              </div>




              <!--  Select Filter  -->
              <div class="mb-2 mr-4" *ngIf="
              (item.dataType === 'SELECT') &&
              item.enumValues &&
              item.selectValues.length > 0 
            ">
                <div class="selectField form-group">

                  <p class="text-muted text-small">
                    {{ translate.currentLang == 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr }}
                  </p>
                  <ul class="list-unstyled mb-5">

                    <li *ngFor="let item2 of item.selectValues ; let i = index">
                      <div class="custom-control custom-radio mb-2">
                        <input type="radio" [value]="item2.value" (change)="onCheckboxChange(item2 , item.fieldName )"
                          class="custom-control-input" formControlName="{{ item.fieldName }}" id="{{item.fieldName+i}}">
                        <label class="custom-control-label" for="{{item.fieldName + i}}">{{translate.currentLang ===
                          'en'? item2.valueEn : item2.valueAr }}</label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>

                  </ul>

                  <!-- <ng-select
                [items]="item.selectValues"
                bindLabel="name"
                bindValue="id"
                [placeholder]="
                  translate.currentLang == 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr
                "
                
                [searchable]="true"
              >
              </ng-select> -->
                </div>

              </div>
              <div class="d-block d-md-inline-block position-relative" *ngIf="
                item.dataType === 'SELECT' &&
                !item.enumValues &&
                item.selectValues?.length > 0   
              ">

                <div class="selectField form-group">
                  <p class="text-muted text-small">
                    {{ translate.currentLang == 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr }}
                  </p>
                  <ul class="list-unstyled mb-5">

                    <li *ngFor="let item2 of item.selectValues.slice(0,item.maxViewedOptionsCount) ; let i = index">
                      <div class="custom-control custom-radio mb-2">
                        <input type="radio" (change)="onCheckboxChange(item2, item.fieldName )" [name]="item.fieldName"
                          [value]="item2.optionValue" class="custom-control-input"
                          formControlName="{{ item.fieldName }}" [id]="item.fieldName + i">
                        <label class="custom-control-label" for="{{item.fieldName  +i}}">{{ item2.name }}</label>
                      </div>
                    </li>
                    <a style="font-size: 12px ; font-weight: bold;"  *ngIf="viewSeeMore(item)" href="javascript:;" (click)="showAllOptions(item)"  >{{("filters.see-all" | translate).replace('{NO}' , item.selectValues.length).replace('{fname}' , translate.currentLang == 'en'? item.placeHolderEn: item.placeHolderAr )}}  </a>

                  </ul>


                  <!-- <ng-select
                  [items]="item.selectValues"
                  bindLabel="name"
                  [placeholder]="
                    translate.currentLang == 'en'
                      ? item.placeHolderEn
                      : item.placeHolderAr
                  "
                  formControlName="{{ item.fieldName }}"
                  [searchable]="true"
                >
                </ng-select>   -->
                </div>
              </div>
         
            <!--  Date Filter -->
            <div *ngIf="item.dataType === 'DATE'" class="input-group-prepend mr-3 mb-5">

              <div class="input-group-prepend">
                <span class="input-group-text"> <i class="iconsminds-calendar-4"> </i></span>
              </div>

              <input type="text" placeholder="{{
                translate.currentLang === 'en'
                  ? item.placeHolderEn
                  : item.placeHolderAr
              }}" class="form-control" formControlName="{{ item.fieldName}}" bsDatepicker [container]="'html'"
                [bsConfig]="dateConfig">
            </div>
            <div class="mb-2 mr-4 " *ngIf="item.dataType === 'DATE_TIME'    "
              class="input-group-prepend mr-3 mt-3 mb-5">
              
              <div class="input-group-text">
                <i class="iconsminds-calendar-4"> </i>
              </div>


              <!-- <input type="text" bsDatepicker [container]="'html'" formControlName="{{ item.fieldName }}"
                theme="dp-material" placeholder="{{
                  translate.currentLang === 'en'
                    ? item.placeHolderEn
                    : item.placeHolderAr
                }}" [bsConfig]="{ adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }" /> -->


                <br/>
                    
                        <input name="dateSingle" 
                        formControlName="{{ item.fieldName }}"
                        [owlDateTime]="dateSingle" 
                        placeholder="{{
                          translate.currentLang === 'en'
                            ? item.placeHolderEn
                            : item.placeHolderAr
                        }}"
                        [owlDateTimeTrigger]="dateSingle" 
                        class="form-control" 
                        [selectMode]="'single'" />

                        <owl-date-time [firstDayOfWeek]="'1'" 
                       
                        #dateSingle></owl-date-time>

            </div>

            <!-- *ngIf="
              item.dataType === 'STRING' ||
              item.dataType === 'DOUBLE' ||
              item.dataType === 'LONG'    
            "  -->
            <div class="mb-2 mr-4" *ngIf="item.dataType === 'DOUBLE'   ">
              <div class="form-group">
                <input [id]="item.fieldName" type="text" class="form-control" formControlName="{{ item.fieldName }}"
                  type="text" [placeholder]="
                    translate.currentLang == 'en'
                      ? item.placeHolderEn
                      : item.placeHolderAr
                  " />
              </div>
            </div>
            <div class="mb-2 mr-4 mt-2" *ngIf="item.dataType === 'BOOLEAN'   ">
              <div class="form-group">
                <label class="form-check-label ml-4">
                  <input [id]="item.fieldName" class="form-check-input" formControlName="{{ item.fieldName }}"
                    type="checkbox" [placeholder]="item.fieldName" />
                  <i class="input-frame"></i>
                  {{
                  translate.currentLang == "en"
                  ? item.placeHolderEn
                  : item.placeHolderAr
                  }}
                </label>
              </div>
            </div>
          </div>
   
      </div>
    </form>
  </div>
</div>