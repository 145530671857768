<div class="d-flex justify-center mt-5" *ngIf="notValidToken">
    <div class="card auth-card">
        <div class="form-side">
            <h6> Not Valid Token - لايوجد رمز تحقق </h6>
            <div class="d-flex  justify-content-center">
                <button type="submit" class="btn btn-primary mx-2" routerLink="/">
                    Home - الصفحة الرئيسية
                </button>

            </div>
        </div>

    </div>
</div>

<div class="d-flex justify-center mt-5" *ngIf="confirmMessageData?.errorMsgEn || confirmMessageData?.errorMsgAr">
    <div class="card auth-card">
        <div class="form-side">
            <div class="m-4">{{confirmMessageData?.errorMsgEn}}</div>
            <div class="m-4">{{confirmMessageData?.errorMsgAr}}</div>
            <div class="d-flex  justify-content-center m-d">
                <button type="submit" class="btn btn-primary mx-2" routerLink="/">
                    Home - الصفحة الرئيسية
                </button>

            </div>
        </div>

    </div>
</div>



<div class="d-flex justify-center w-90 mt-5" *ngIf="confirmMessageData.depositionTransactionId">
    <div class="card auth-card">
        <div class="form-side">

            <h6 class="mb-4">{{ 'Cash Deposit Request Confirmation' | translate }}</h6>
            <div *ngIf="!confirmMessageData?.bankDeposition">
                <p>
                    Dear {{confirmMessageData.cashWalletOwnerName}},
                    A new deposition transaction request was submitted to transfer an amount of
                    {{confirmMessageData.amount}} SAR from your cash
                    wallet to {{confirmMessageData.requestedBy}}'s cash wallet. please confirm this transaction by
                    clicking on the following
                    confirmation
                    button.

                </p>
                <p dir="rtl">

                    تم تقديم طلب معاملة إيداع جديد لتحويل مبلغ {{confirmMessageData.amount}} ريال سعودي من
                    محفظتك النقدية إلى محفظة {{confirmMessageData.requestedBy}}
                    النقدية.
                    يرجى
                    تأكيد هذه المعاملة بالضغط على زر التأكيد التالي


                </p>
                <p dir="rtl"> آپ کے کیش والیٹ سے {{confirmMessageData.amount}} کے کیش والیٹ میں
                    {{confirmMessageData.requestedBy}} SAR کی رقم منتقل کرنے کے لیے ایک نئی ڈیپوزیشن
                    ٹرانزیکشن
                    کی
                    درخواست جمع کرائی گئی تھی۔ براہ کرم درج ذیل تصدیقی بٹن پر کلک کرکے اس لین دین کی تصدیق کریں۔
                </p>
            </div>

            <div *ngIf="confirmMessageData?.bankDeposition">
                <p>
                    Hi

                    {{confirmMessageData.cashWalletOwnerName}} has deposited the amount of {{confirmMessageData.amount}}
                    SAR
                    with reference number {{confirmMessageData.bankDepositionRef}}, please click on the confirmation
                    button once the amount is reflected in the bank account

                </p>
                <p dir="rtl">


                    قام {{confirmMessageData.cashWalletOwnerName}} بإيداع مبلغ {{confirmMessageData.amount}} ريال سعودي
                    برقم مرجعي {{confirmMessageData.bankDepositionRef}} الرجاء النقر فوق زر التأكيد بمجرد ظهور المبلغ في
                    الحساب المصرفي


                </p>

            </div>
            <div class="d-flex  justify-content-center">
                <button type="submit" class="btn btn-primary mx-2" (click)="onConfirm()">
                    Confirm - تأكيد
                </button>
                <button type="button" class="btn btn-secondary mx-2" (click)="onReject()">
                    Reject - رفض
                </button>
            </div>
        </div>


    </div>
</div>