<div class="row" *ngIf="authorized">
    <div class="col-12 col-md-12 col-xl-4 col-left mt-4">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">
                  <span>{{'RESOURCES.FLEET.LIVE_TRACKING_INFO' | translate}}</span>
                </h5>
                <div class="detail-content" *ngIf="fleetLiveTrackingInfo">
                    <h3 class="fs-14 mb-1">{{fleetLiveTrackingInfo?.trackingTime}}</h3>
                    <h5 class="fs-12 mb-4">{{fleetLiveTrackingInfo?.currentLocationAddress}}</h5>
                    <div class="tab-pane fade show active" id="nav-speci" role="tabpanel" aria-labelledby="nav-speci-tab">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <tbody>
                                <tr>
                                    <th scope="row" style="width: 200px;">{{'RESOURCES.FLEET.STATUS' | translate}}</th>
                                    <td>
                                        <span class="badge text-uppercase" [ngClass]="{ 
                                            'badge-soft-success': fleetLiveTrackingInfo?.status === 'MOVING',
                                            'badge-soft-danger': fleetLiveTrackingInfo?.status === 'STOPPED'}">
                                            {{translate.currentLang === 'ar' ? fleetLiveTrackingInfo?.statusAr : fleetLiveTrackingInfo?.statusEn}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">{{'RESOURCES.FLEET.DRIVER_FULLNAME' | translate}}</th>
                                    <td>{{fleetLiveTrackingInfo?.driverInfo?.fullName}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{{'RESOURCES.FLEET.DRIVER_MOBILENO' | translate}}</th>
                                    <td>{{fleetLiveTrackingInfo?.driverInfo?.mobileNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-12 col-xl-8 col-right mt-4">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="card-title">
                  <span>{{'RESOURCES.FLEET.LIVE_TRACKING' | translate}}</span>
                  <div class="top-right-button-container mb-2">
                    <button type="button" (click)="openGoogleMapsUrl()" class="btn btn-outline-primary mb-1 mr-2">
                        Open on Google Maps
                    </button>
                  </div>
                </h5>
                <div class="col-md-12 col-xl-12 left-wrapper" *ngIf="fleetLiveTrackingInfo">
                    <div class="card rounded">
                      <div class="card-body">
                        <agm-map [zoom]="11" [latitude]="fleetLiveTrackingInfo?.currentLocation?.lat"
                            [longitude]="fleetLiveTrackingInfo?.currentLocation?.lon">
                            <agm-marker [latitude]="fleetLiveTrackingInfo?.currentLocation?.lat" 
                                        [longitude]="fleetLiveTrackingInfo?.currentLocation?.lon">
                            </agm-marker>
                        </agm-map>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!authorized" class="auth-page-content overflow-hidden p-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-7 col-lg-8">
                <div class="text-center">
                    <!-- <img src="assets/images/error400-cover.png" alt="error img" class="img-fluid"> -->
                    <img src="assets/images/logo-dark.png" alt="" height="56">
                    <div class="mt-3">
                        <h3 class="text-uppercase">{{ 'COMMON.SORRY_PAGE_NOT_FOUND' | translate }} 😭</h3>
                        <p class="text-muted mb-4">{{ 'COMMON.THE_PAGE_YOU_ARE_LOOKING_FOR_NOT_AVAILABLE' | translate }}</p>
                        <a routerLink="" class="btn btn-primary"><i class="mdi mdi-home me-1"></i>{{ 'COMMON.BACK_TO_HOME' | translate }}</a>
                    </div>
                </div>
            </div><!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>