import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { constants } from '../constants/constants';

@Injectable({
	providedIn: 'root'
})
export class SessionDataService {
	private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

	private currentUserPhotoSubject: BehaviorSubject<any>;
    public currentUserPhoto: Observable<any>;

	navigationDataSessionStorageKey:string = "NAVIGATION_DATA";
	
    navigationData:any =JSON.parse(localStorage.getItem(this.navigationDataSessionStorageKey)) || {};

	constructor() {
		const rememberMe:any = JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.REMEMBER_ME) || "false");
		const user:User = rememberMe?JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.CURRENT_USER)|| '{}'):
		JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.CURRENT_USER)|| '{}')
		this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();

		this.currentUserPhotoSubject = new BehaviorSubject<any>("");
        this.currentUserPhoto = this.currentUserPhotoSubject.asObservable();
	}

	setAccessToken(token:any) {
		const rememberMe:any = JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.REMEMBER_ME) || "false");
		if(rememberMe){
			localStorage.setItem(constants.PRISISTED_KEYS.ACCESS_TOKEN, token);
		}else{
			localStorage.setItem(constants.PRISISTED_KEYS.ACCESS_TOKEN, token);
		}
		
	}

	getAccessToken(): any {
		let token = null;
		const rememberMe:any = JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.REMEMBER_ME) || "false");
		if(rememberMe){
			token = localStorage.getItem(constants.PRISISTED_KEYS.ACCESS_TOKEN);
		}else{
			token = localStorage.getItem(constants.PRISISTED_KEYS.ACCESS_TOKEN);
		}
		return token;
	}

	setcurrentUser(currentUser:any) {
		if(currentUser){
			const rememberMe:any = JSON.parse(localStorage.getItem(constants.PRISISTED_KEYS.REMEMBER_ME) || "false");
			if(rememberMe){
				localStorage.setItem(constants.PRISISTED_KEYS.CURRENT_USER, JSON.stringify(currentUser));
			}else{
				localStorage.setItem(constants.PRISISTED_KEYS.CURRENT_USER, JSON.stringify(currentUser));
			}
		}else{
			localStorage.removeItem(constants.PRISISTED_KEYS.CURRENT_USER);
			//sessionStorage.removeItem(constants.PRISISTED_KEYS.CURRENT_USER);
		}
		this.currentUserSubject.next(currentUser);
	}

	getcurrentUser(): User {
		return this.currentUserSubject.value;
	}

	setcurrentUserPhoto(currentUserPhoto:any) {
		this.currentUserPhotoSubject.next(currentUserPhoto);
	}

	getcurrentUserPhoto(): User {
		return this.currentUserPhotoSubject.value;
	}

	addNavigationDataEntry(page:string,navigationDataEntryData:any){
        this.navigationData[page] = navigationDataEntryData;
        localStorage.setItem(this.navigationDataSessionStorageKey, JSON.stringify(this.navigationData));
    }

    getNavigationDataEntry(page:string){
        return this.navigationData[page];
    }
}
