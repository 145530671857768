import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthGuard } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { SessionDataService } from 'src/app/services/session-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProfileService } from 'src/app/services/profile.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationsService,NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Ahmed Farid';
  languages: Language[];
  currentLanguage: string;
  currentUser:any ; 
  userImage:any ; 
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  notifications: any[] = [];
  showNotificationAlert: boolean;
  constructor(
    private sidebarService: SidebarService,
    private authService: AuthGuard,
    private router: Router,
    private langService: LangService , 
    private sessionDataService: SessionDataService, 
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private notificationService:NotificationService,
    private notificationsService: NotificationsService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.currentLanguage = localStorage.getItem("language");
    this.sessionDataService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.sessionDataService.currentUserPhoto.subscribe(
      (x) => (this.userImage = x)
    );
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
   
  }

  async ngOnInit(): Promise<void> {
    if (await this.authService.getCurrentUserProfile()) {
      this.displayName = await this.authService.getCurrentUserProfile().then((user) => {
        return user.displayName;
      });
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    this.currentLanguage = this.langService.languageShorthand;
     
    if (Object.keys(this.sessionDataService.getcurrentUser()).length != 0) {
      if (this.sessionDataService.getcurrentUserPhoto()) {
        this.userImage = this.sessionDataService.getcurrentUserPhoto();
      } else {
        const res = await this.profileService.getUserPhoto();
        let objectURL = URL.createObjectURL(res);
        this.userImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.sessionDataService.setcurrentUserPhoto(this.userImage);
      }
    }

    this.notificationService
      .onMessage("/user/queue/notifications")
      .subscribe((notification) => {
        this.notifications.unshift(notification);
        this.showNotificationAlert = true;
        this.notificationsService.create(
          notification.subjectEn,
          notification.summaryEn,
          NotificationType.Bare,
          {
            theClass: 'outline primary',
            timeOut: 6000,
            showProgressBar: false,
          }
        );
      });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.authService.logout() ;
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
     if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

    onAccountClick(): void{
      this.router.navigate([this.adminRoot + '/profile']) ;  
    }

    checkNotificationDropDown(open: boolean) {
      if (open) {
        this.showNotificationAlert = false;
      }
    }
  
    openNotification(notification: any) {
      let url = "/notifications?notificationId=" + notification.id;
      this.router.navigateByUrl(url);
    }
  

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
