import { environment } from 'src/environments/environment';
import { constants } from '../constants/constants';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: any[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-digital-drawing',
    label: 'MENU.DASHBOARD',
    to: `${adminRoot}/dashboard`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor,
      constants.USER_ROLES.accountant
    ],
    subs: [
      {
        icon: 'iconsminds-monitor-analytics',
        label: 'MENU.DASHBOARD_ANALYTICS',
        to: `${adminRoot}/dashboard`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-management',
        label: 'MENU.DASHBOARD_OPERATIONS',
        to: `${adminRoot}/dashboard/operations`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      }
    ]
  },
  {
    icon: 'iconsminds-checkout',
    label: 'MENU.SERVICE_ORDERS',
    to: `${adminRoot}/dashboard/serviceOrdersOperations`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.serviceProvider,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor,
      constants.USER_ROLES.accountant
    ],
    subs: [
      {
        icon: 'simple-icon-plus',
        label: 'MENU.SERVICE_ORDERS.CREATE_NEW',
        to: `${adminRoot}/service-orders/new-order`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor
        ]
      },
      {
        icon: 'iconsminds-management',
        label: 'MENU.SERVICE_ORDER_OPERATIONS',
        to: `${adminRoot}/dashboard/serviceOrdersOperations`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-edit-map',
        label: 'MENU.ORDER_OPERATIONS_MAP',
        to: `${adminRoot}/dashboard/ordersOperationsMap`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-align-justify-all',
        label: 'MENU.SERVICE_ORDERS.ALL_ORDERS',
        to: `${adminRoot}/service-orders/orders/all`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-check',
        label: 'MENU.SERVICE_ORDERS.SERVICE_PROVIDED',
        to: `${adminRoot}/service-orders/orders/SERVICE_PROVIDED`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-check',
        label: 'MENU.SERVICE_ORDERS.ASSIGNED',
        to: `${adminRoot}/service-orders/orders/ASSIGNED`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-reset',
        label: 'MENU.SERVICE_ORDERS.UPCOMING',
        to: `${adminRoot}/service-orders/orders/UPCOMING`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-next',
        label: 'MENU.SERVICE_ORDERS.INPROGRESS',
        to: `${adminRoot}/service-orders/orders/IN_PROGRESS`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-yes',
        label: 'MENU.SERVICE_ORDERS.COMPLETED',
        to: `${adminRoot}/service-orders/orders/COMPLETED`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      },
      {
        icon: 'iconsminds-close',
        label: 'MENU.SERVICE_ORDERS.CANCELLED',
        to: `${adminRoot}/service-orders/orders/CANCELLED`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor,
          constants.USER_ROLES.accountant
        ]
      }
    ],
  },
  {
    icon: 'simple-icon-emotsmile',
    label: 'MENU.CLIENT_SUCCESS',
    to: `${adminRoot}/customer-management`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor],
    subs: [
      {
        icon: 'simple-icon-people',
        label: 'MENU.USER_MANAGEMENT.CUSTOMERS',
        to: `${adminRoot}/customer-management`,
        roles: [constants.USER_ROLES.admin,
                constants.USER_ROLES.operationManager,
                constants.USER_ROLES.stationSupervisor,
                constants.USER_ROLES.supervisor],
      },
      {
        icon: 'iconsminds-microphone-4',
        label: 'MENU.ANNOUNCEMENTS',
        to: `${adminRoot}/announcements`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager],
      },
      {
        icon: 'iconsminds-pricing',
        label: 'MENU.COUPONS',
        to: `${adminRoot}/coupons`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager],
      },
      {
        icon: 'iconsminds-support',
        label: 'MENU.SUPPORT_TICKETS',
        to: `${adminRoot}/support-tickets/tickets`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor],
      },
      {
        icon: 'iconsminds-synchronize-2',
        label: 'MENU.RESOURCES.BUNDLE-SUBSCRIPTIONS',
        to: `${adminRoot}/resources/bundle-subscriptions`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.serviceProvider,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor],
      },
    ]
  },
  {
  
    icon: 'iconsminds-billing',
    label: 'MENU.PAYMENT_MANAGEMENT',
    to: `${adminRoot}/payment-management/payment-history`,
    roles: [constants.USER_ROLES.admin,
            constants.USER_ROLES.operationManager,
            constants.USER_ROLES.supervisor,
            constants.USER_ROLES.accountant
          ],
    subs: [ 
      {
        icon: 'iconsminds-coins',
        label: 'MENU.PAYMENT_MANAGEMENT.PAYMENT_LOGS',
        to: `${adminRoot}/payment-management/payment-history`,
        roles: [constants.USER_ROLES.admin,
                constants.USER_ROLES.operationManager,
                constants.USER_ROLES.supervisor,
                constants.USER_ROLES.accountant
              ]
      }, 
      {
        icon: 'iconsminds-testimonal',
        label: 'MENU.PAYMENT_MANAGEMENT.INVOICES_LOGS',
        to: `${adminRoot}/payment-management/invoices`,
        roles: [constants.USER_ROLES.admin,
                constants.USER_ROLES.operationManager,
                constants.USER_ROLES.supervisor,
                constants.USER_ROLES.accountant
              ],
      },
	    {
        icon: 'iconsminds-money-bag',
        label: 'MENU.PAYMENT_MANAGEMENT.CASHBOOK',
        to: `${adminRoot}/payment-management/cashbook`,
        roles: [
          constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.accountant
        ],
      },
      {
        icon: 'iconsminds-cash-register-2',
        label: 'MENU.RESOURCES.POINT_OF_SALE',
        to: `${adminRoot}/payment-management/point-of-sale`,
        roles: [constants.USER_ROLES.admin],
      },                                                                                         
    ],
  },
  {
    icon: 'iconsminds-open-book',
    label: 'MENU.RESOURCES.SERVICE_CATALOG',
    to: `${adminRoot}/resources/catalog`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor],
    subs: [
      {
        icon: 'iconsminds-open-book',
        label: 'MENU.RESOURCES.SERVICE_CATALOG',
        to: `${adminRoot}/resources/catalog`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor],
      },
      {
        icon: 'iconsminds-big-data',
        label: 'MENU.RESOURCES.SERVICE_BUNDLES',
        to: `${adminRoot}/resources/service-bundles`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor],
      },
      {
        icon: 'simple-icon-grid',
        label: 'MENU.RESOURCES.SERVICE_CATEGORIES',
        to: `${adminRoot}/resources/category`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager],
      },
      {
        icon: 'iconsminds-financial',
        label: 'MENU.RESOURCES.DISCOUNT_TEMPLATE',
        to: `${adminRoot}/payment-management/discounts`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager],
          
      },
    ]
  },
  {
    icon: 'simple-icon-layers',
    label: 'MENU.RESOURCES',
    to: `${adminRoot}/resources/fleet`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor],
    subs: [
      {
        icon: 'iconsminds-car',
        label: 'MENU.RESOURCES.SERVICE_FLEET',
        to: `${adminRoot}/resources/fleet`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor],
      },
      {
        icon: 'iconsminds-shop',
        label: 'MENU.RESOURCES.SERVICE_STATIONS',
        to: `${adminRoot}/resources/stations`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor,
          constants.USER_ROLES.stationAttendant,
          constants.USER_ROLES.stationSupervisor],
      },
      {
        icon: 'iconsminds-shop-4',
        label: 'MENU.INVENTORY_STOCK.STORE_HOUSE',
        to: `${adminRoot}/inventory/store-house`,
        roles: [constants.USER_ROLES.admin,],
      },
      {
        icon: 'iconsminds-car-items',
        label: 'MENU.INVENTORY_STOCK.STOCK_ITEM',
        to: `${adminRoot}/inventory/stock-items`,
        roles: [constants.USER_ROLES.admin],
      } ,
      {
        icon: 'iconsminds-arrow-mix',
        label: 'MENU.INVENTORY_STOCK.STOCK_TX',
        to: `${adminRoot}/inventory/transactions`,
        roles: [constants.USER_ROLES.admin,
                constants.USER_ROLES.operationManager,
                constants.USER_ROLES.supervisor],
      },
    ],
  },
  {
    icon: 'iconsminds-user',
    label: 'MENU.USER_MANAGEMENT',
    to: `${adminRoot}/users-management/users/USER`,
    roles: [constants.USER_ROLES.admin,
            constants.USER_ROLES.operationManager,
            constants.USER_ROLES.supervisor],
    subs: [
      {
        icon: 'iconsminds-male',
        label: 'MENU.USER_MANAGEMENT.ALL_USERS',
        to: `${adminRoot}/users-management/users/USER`,
        roles: [constants.USER_ROLES.admin,],
      },
      {
        icon: 'iconsminds-user',
        label: 'MENU.USER_MANAGEMENT.SERVICE_PROVIDERS',
        to: `${adminRoot}/users-management/users/SERVICE_PROVIDER`,
        roles: [constants.USER_ROLES.admin],
      },
      {
        icon: 'iconsminds-administrator',
        label: 'MENU.USER_MANAGEMENT.ADMIN_USERS',
        to: `${adminRoot}/users-management/users/ADMINS_USERS`,
        roles: [constants.USER_ROLES.admin],
      }
    ],
  },
  {
    icon: 'iconsminds-gears',
    label: 'MENU.CONFIGURATIONS',
    to: `${adminRoot}/configurations/vehicle-make-model`,
    roles: [constants.USER_ROLES.admin,
      constants.USER_ROLES.operationManager,
      constants.USER_ROLES.supervisor,
      constants.USER_ROLES.stationAttendant,
      constants.USER_ROLES.stationSupervisor],
    subs: [
      // {
      //   icon: 'iconsminds-map-marker',
      //   label: 'MENU.CONFIGURATIONS.OPERATIONS_AREA',
      //   to: `${adminRoot}/configurations/operations-areas`,
      //   roles: [constants.USER_ROLES.admin,
      //     constants.USER_ROLES.operationManager,
      //     constants.USER_ROLES.supervisor],
      // },
      {
        icon: 'iconsminds-gears',
        label: 'MENU.CONFIGURATIONS.SYSTEM_CONFIG',
        to: `${adminRoot}/configurations/system-config`,
        roles: [constants.USER_ROLES.admin],
      },
      {
        icon: 'iconsminds-car',
        label: 'MENU.CONFIGURATIONS.VEHICLE_MAKE_MODEL',
        to: `${adminRoot}/configurations/vehicle-make-model`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor],
      },
      {
        icon: 'iconsminds-globe-2',
        label: 'MENU.CONFIGURATIONS.NATIONALITIES',
        to: `${adminRoot}/configurations/nationalities`,
        roles: [constants.USER_ROLES.admin,
          constants.USER_ROLES.operationManager,
          constants.USER_ROLES.supervisor],
      },
    ]
  },
];
export default data;
