import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FleetService } from 'src/app/services/fleet.service';

@Component({
  selector: 'app-track-fleet',
  templateUrl: './track-fleet.component.html',
  styleUrls: ['./track-fleet.component.scss']
})
export class TrackFleetComponent implements OnInit {
  readonly TOKEN_PARAM: string = 'token';
  token: string;
  authorized: boolean = true;
  fleetLiveTrackingInfo: any;
  constructor(private route: ActivatedRoute, private fleetService: FleetService, public translate: TranslateService) { }

  ngOnInit(): void {
  const token = this.route.snapshot.queryParamMap.get(this.TOKEN_PARAM);
    if(token) {
      this.fleetService.loadLiveTrackingInfo(token)
          .subscribe({
            next: this.handleSuccess.bind(this),
            error: this.handleFailure.bind(this)
          });
    } else {
      this.authorized = false;
    }
  }

  openGoogleMapsUrl(){
    window.open(this.fleetLiveTrackingInfo.googleMapsUrl, "_blank");
  }

  private handleSuccess(data: any): void {
    this.authorized = true;
    this.fleetLiveTrackingInfo = data;
  }

  private handleFailure(error: any): void {
    this.authorized = false;
  }

}
